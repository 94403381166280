import React, { useRef } from 'react'
import Slider from 'react-slick'
import { ServiceCardType, } from '../../types'
import PrevArrow from '../../assets/images/icons/prev-arrow-resource.svg'
import NextArrow from '../../assets/images/icons/next-arrow-resource.svg'
import ServicesSliderCard from './ServicesSliderCard';

type ServicesSliderProps = {
  title: string
  text: string
  items: ServiceCardType[]
}

const ServicesSlider = ({
                          items,
                          text,
                          title,
                        }: ServicesSliderProps) => {

  const sliderRef = useRef(null);

  const settings = {
    infinite: false,
    slidesToShow: 4,
    slidesToScroll: 4,
    arrows: false,
    dots: false,
    draggable: false,
    responsive: [
      {
        breakpoint: 1199,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const slides = items?.filter(q => q.content).map((card, i) => (
    <ServicesSliderCard
      key={`services-slider-${i}`}
      card={card}
    />
  ));

  return (
    <>

      <section className="c-servicesSlider">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xxl-10">
              <div className="c-servicesSlider__box">
                <div className="row mb-3 mb-sm-5">
                  <div className="col-12 mb-2 mb-sm-3">
                    <h2 className="section-heading section-heading--mobilexs18">
                      {title}
                    </h2>
                  </div>
                </div>

                <div className="row pt-1">
                  <div className="col-lg-10 col-xl-7 col-xxl-5">
                    <h3 className="section-subheading">{text}</h3>
                  </div>
                </div>

                <div className="c-serviceSlider__slider">
                  <Slider ref={sliderRef} {...settings}>
                    {slides}
                  </Slider>
                </div>

                <div className="row">
                  <div className="col-12">
                    <div className="c-resourceContent__buttons d-flex justify-content-end">
                      <button onClick={() => sliderRef.current.slickPrev()} className="c-resourceContent__button">
                        <div className="c-resourceContent__hoverButton d-flex justify-content-center align-items-center">
                          <PrevArrow/>
                        </div>
                      </button>

                      <button onClick={() => sliderRef.current.slickNext()} className="c-resourceContent__button">
                        <div className="c-resourceContent__hoverButton d-flex justify-content-center align-items-center">
                          <NextArrow/>
                        </div>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
};

export default ServicesSlider
