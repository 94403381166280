import React from "react"

import StoryblokLink from "../shared/StoryblokLink"

import FacebookLogo from "../../assets/images/icons/facebook.svg"
import LinkedinLogo from "../../assets/images/icons/linkedin.svg"
import TwitterLogo from "../../assets/images/icons/twitter.svg"

import { ctaTarget } from "../../types"

type FooterSocialsProps = {
  facebook: ctaTarget
  linkedin: ctaTarget
  twitter: ctaTarget
}

const FooterSocials = ({ facebook, linkedin, twitter }: FooterSocialsProps) => {
  return (
    <div className="row">
      <div className="col-12">
        <div className="footer__socials d-flex justify-content-xl-end align-items-center">
          {facebook?.cached_url && (
            <StoryblokLink to={facebook} className="footer__socials-item">
              <FacebookLogo />
            </StoryblokLink>
          )}
          {linkedin?.cached_url && (
            <StoryblokLink to={linkedin} className="footer__socials-item">
              <LinkedinLogo />
            </StoryblokLink>
          )}
          {twitter?.cached_url && (
            <StoryblokLink to={twitter} className="footer__socials-item">
              <TwitterLogo />
            </StoryblokLink>
          )}
        </div>
      </div>
    </div>
  )
}

export default FooterSocials
