import React from "react"

import Layout from "../components/layout/Layout"
import FormHero from "../components/getInTouch/FormHero"

import { SeoMetaType, StoryblokImageType } from "../types"
import { GetInTouchFormProps } from "../components/getInTouch/GetInTouchForm"

type GetInTouchProps = {
  seoMeta: SeoMetaType
  title: any
  text: string
  image: StoryblokImageType
  pathname: string
  form: { content: GetInTouchFormProps }
}

const GetInTouchPage = ({
  seoMeta,
  title,
  text,
  image,
  form,
  pathname,
}: GetInTouchProps) => {
  return (
    <Layout contentClass="GetInTouchPage" seoMeta={seoMeta} pathname={pathname}>
      <FormHero title={title} text={text} image={image} form={form} />
    </Layout>
  )
}

export default GetInTouchPage
