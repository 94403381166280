import React, { useState } from "react"

import Layout from "../components/layout/Layout"
import OnDemandWebinarHero from "../components/onDemandWebinar/OnDemandWebinarHero"
import YoutubeOverlay from "../components/onDemandWebinar/YoutubeOverlay"
import OnDemandInfo from "../components/onDemandWebinar/OnDemandInfo"
import CtaBanner from "../components/shared/CtaBanner"

import {
  SeoMetaType,
  BlogAuthorType,
  StoryblokImageType,
  ctaTarget,
  CtaBannerType,
} from "../types"

type OnDemandWebinarType = {
  seoMeta: SeoMetaType
  pathname: string
  title: any
  watchTime: string
  presenters: { content: BlogAuthorType }[]
  youtubeImage: StoryblokImageType
  youtubeEmbedLink: ctaTarget
  detailTitle: string
  detailText: any
  ctaBanner: CtaBannerType
}

const OnDemandWebinar = ({
  seoMeta,
  pathname,
  title,
  watchTime,
  presenters,
  youtubeImage,
  youtubeEmbedLink,
  detailTitle,
  detailText,
  ctaBanner,
}: OnDemandWebinarType) => {
  const [isYoutubeOpened, setYoutubeOpened] = useState(false)

  return (
    <Layout
      contentClass="onDemandWebinarPage"
      seoMeta={seoMeta}
      pathname={pathname}
      hasOverlay
    >
      <OnDemandWebinarHero
        title={title}
        watchTime={watchTime}
        presenters={presenters}
        youtubeImage={youtubeImage}
        openYoutubeOverlay={() => setYoutubeOpened(true)}
      />
      <OnDemandInfo title={detailTitle} text={detailText} />
      <CtaBanner ctaBanner={ctaBanner} />
      <YoutubeOverlay
        link={youtubeEmbedLink.url}
        isOpen={isYoutubeOpened}
        closeYoutubeOverlay={() => setYoutubeOpened(false)}
      />
    </Layout>
  )
}

export default OnDemandWebinar
