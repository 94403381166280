import React from "react"
import { ctaTarget } from "../../types"

import StoryblokLink from "../shared/StoryblokLink"

type NavDropdownMobileMainLinkProps = {
  label: string
  link: ctaTarget
}

const NavDropdownMobileMainLink = ({
  label,
  link,
}: NavDropdownMobileMainLinkProps) => {
  return (
    <li className="c-mobileSubMenu__content-item">
      <StoryblokLink to={link} className="hover-bcgUnderline">
        {label}
      </StoryblokLink>
    </li>
  )
}

export default NavDropdownMobileMainLink
