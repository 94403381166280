import React from "react"

import { OfficeType } from "../../types"

type OfficeItemProps = {
  office: OfficeType
}

const OfficeItem = ({ office }: OfficeItemProps) => {
  return (
    <div className="c-offices__item">
      <div className="c-offices__mapWrapper">
        <iframe src={office?.mapSrc} />
      </div>

      <h4 className="c-offices__name">{office?.title}</h4>

      <div className="c-offices__detail">
        <p>
          <strong>{office?.companyName}</strong>
        </p>
        <p>{office?.address1}</p>
        <p>{office?.address2}</p>
        <p>{office?.id}</p>
        <p>{office?.vat}</p>
      </div>

      <div className="c-offices__contact">
        <p>
          <strong>{office?.callOrMail}</strong>
        </p>
        <p>
          tel: <a href={`tel:${office?.tel}`}>{office?.tel}</a>
        </p>
        <p>
          e-mail: <a href={`tel:${office?.email}`}>{office?.email}</a>
        </p>
        <p>{office?.info}</p>
      </div>
    </div>
  )
}

export default OfficeItem
