import React from "react"
import scrollTo from "gatsby-plugin-smoothscroll"

import { ctaTarget } from "../../types"

type ScrollCtaType = {
  text: string
  label: string
  link: ctaTarget
}

const ScrollCTA = ({ text, label, link }: ScrollCtaType) => {
  return (
    <section className="c-scrollCta d-none d-xl-block">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xxl-10">
            <div className="c-scrollCta__content">
              <div className="row justify-content-center">
                <div className="col-lg-8 col-xxl-6">
                  <div className="c-scrollCta__box">
                    {text && <p className="c-scrollCta__text">{text}</p>}

                    <div className="text-center">
                      {link?.cached_url && label && (
                        <span
                          className="btn-primary c-scrollCta__button"
                          onClick={() => scrollTo(link.cached_url)}
                        >
                          {label}
                        </span>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default ScrollCTA
