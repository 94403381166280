import React, { useState } from "react"

import Layout from "../components/layout/Layout"
import Hero from "../components/shared/Hero"
import Benefits from "../components/solutionDetail/Benefits"
import Services from "../components/solutionDetail/Services"
import Learn from "../components/shared/Learn"
import HowItWorksBanner from "../components/shared/HowItWorksBanner"
import Testimonials from "../components/home/Testimonials"
import ThreeBlock from "../components/shared/ThreeBlock"
import PersonBanner from "../components/solutionDetail/PersonBanner"
import CtaBanner from "../components/shared/CtaBanner"
import FormOverlay from "../components/solutionDetail/FormOverlay"

import { ProjectEstimateFormProps } from "../components/services/ProjectEstimateForm"

import {
  SeoMetaType,
  HeaderType,
  BenefitCardType,
  ServiceCardType,
  Resource,
  Testimonial,
  CtaBannerType,
  ThreeCard,
  HowBannerType,
  StoryblokImageType,
  ctaTarget,
} from "../types"

type SolutionDetailProps = {
  seoMeta: SeoMetaType
  pathname: string
  header: HeaderType[]
  benefitsTitle: string
  benefitsText: string
  benefitsCards: BenefitCardType[]
  servicesTitle: string
  servicesText: string
  servicesList: ServiceCardType[]
  caseStudyTitle: string
  caseStudyText: string
  caseStudy: Resource[]
  solutionTestimonials: Testimonial[]
  learnMoreTitle: string
  resources: ThreeCard[]
  ctaBanner: CtaBannerType
  howItWorks: HowBannerType
  personTitle: string
  personImage: StoryblokImageType
  personName: string
  personPosition: string
  personLinkedin: ctaTarget
  personMail: ctaTarget
  personText: string
  personCta: string
  personFormTitle: string
  personFormSource: string
  personForm: { content: ProjectEstimateFormProps }
}

const SolutionDetail = ({
  seoMeta,
  pathname,
  header,
  benefitsTitle,
  benefitsText,
  benefitsCards,
  servicesTitle,
  servicesText,
  servicesList,
  caseStudyTitle,
  caseStudyText,
  caseStudy,
  solutionTestimonials,
  ctaBanner,
  learnMoreTitle,
  resources,
  howItWorks,
  personTitle,
  personImage,
  personName,
  personPosition,
  personLinkedin,
  personMail,
  personText,
  personCta,
  personFormTitle,
  personFormSource,
  personForm,
}: SolutionDetailProps) => {
  const [isFormOpened, setFormOpened] = useState(false)

  const initiateFormOverlay = (settings: boolean) => {
    setFormOpened(settings)
    document.body.classList.toggle("noScroll")
  }

  const areTestimonials = solutionTestimonials[0]?.testimonials?.length > 0

  return (
    <Layout
      contentClass="SolutionDetailPage"
      seoMeta={seoMeta}
      pathname={pathname}
      hasOverlay
    >
      <Hero header={header} />
      <Benefits
        title={benefitsTitle}
        text={benefitsText}
        cards={benefitsCards}
      />
      <Services
        title={servicesTitle}
        text={servicesText}
        items={servicesList}
      />
      <HowItWorksBanner content={howItWorks} />
      {/* <Learn title={caseStudyTitle} text={caseStudyText} resource={caseStudy} /> */}
      {areTestimonials && (
        <Testimonials testimonials={solutionTestimonials} whiteBcg />
      )}
      <ThreeBlock title={learnMoreTitle} cards={resources} />
      <PersonBanner
        title={personTitle}
        image={personImage}
        name={personName}
        position={personPosition}
        linkedin={personLinkedin}
        mail={personMail}
        text={personText}
        cta={personCta}
        openFormOverlay={() => initiateFormOverlay(true)}
      />
      <CtaBanner ctaBanner={ctaBanner} />
      <FormOverlay
        isOpen={isFormOpened}
        closeFormOverlay={() => initiateFormOverlay(false)}
        formTitle={personFormTitle}
        formSource={personFormSource}
        form={personForm}
      />
    </Layout>
  )
}

export default SolutionDetail
