import React from "react"

import patternDark from "../../assets/images/hero-pattern-dark.svg"
import patternOrange from "../../assets/images/hero-pattern-orange.svg"

type ImageHorizontalLargeType = {
  image: string
}

const ImageHorizontalLarge = ({ image }: ImageHorizontalLargeType) => {
  return (
    <div className="c-imageHorizontalLarge">
      <img
        src={patternDark}
        className="c-imageHorizontalLarge__patternDark"
        alt=""
      />
      <img
        src={patternOrange}
        className="c-imageHorizontalLarge__patternOrange"
        alt=""
      />
      <div
        className="c-imageHorizontalLarge__image"
        style={{ backgroundImage: `url(${image})` }}
      />
    </div>
  )
}

export default ImageHorizontalLarge
