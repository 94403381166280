import React from 'react';
import FinancialStatementCard from "./FinancialStatementCard"
import { StoryblokDocumentType } from "../../types"

type FinancialStatementsProps = {
  documentsTitle: string;
  documentLabel: string;
  documentDownloadLabel: string;
  documents: StoryblokDocumentType[];
}

const FinancialStatements = ({ documentsTitle, documentLabel, documentDownloadLabel, documents }: FinancialStatementsProps) => {
  return (
    <section className="c-financialStatements">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xxl-10">
            <div className="c-financialStatements__box">
              <div className="row mb-5">
                <div className="col-12 mb-2 mb-sm-3">
                  <h2 className="section-heading">{documentsTitle}</h2>
                </div>
              </div>
              <div className="row justify-content-start">
                {documents.map((document, index) => (
                  <FinancialStatementCard
                    key={`financialStatement-${index}`}
                    label={documentLabel}
                    downloadLabel={documentDownloadLabel}
                    document={document}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default FinancialStatements
