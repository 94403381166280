import React from "react"

import StoryblokLink from "../shared/StoryblokLink"

import { FiftyFiftyCardType } from "../../types"
import FiftyFiftyBlockBase from "./FiftyFiftyBlockBase"

type FiftyFiftyBlockProps = {
  content: FiftyFiftyCardType
  rightAlign: boolean
  isLast: boolean
}

const FiftyFiftyBlock = ({
  content,
  rightAlign,
  isLast,
}: FiftyFiftyBlockProps) => {

  return (
    <FiftyFiftyBlockBase
      backgroundImageUrl={content?.image.filename}
      rightAlign={rightAlign}
      isLast={isLast}
      displayPatterns={true}
    >
      <h2 className="c-fiftyFiftyBlock__headline">{content?.title}</h2>
      <p className="c-fiftyFiftyBlock__text">{content?.text}</p>
      <div>
        {content?.cta && content?.ctaLink.cached_url && (
          <StoryblokLink
            to={content?.ctaLink}
            className="c-fiftyFiftyBlock__link hover-bcgUnderline"
          >
            {content?.cta}
          </StoryblokLink>
        )}
      </div>
    </FiftyFiftyBlockBase>
  )
}

export default FiftyFiftyBlock
