import React from "react"

import TopThreeItem from "./TopThreeItem"

import patternDark from "../../assets/images/hero-pattern-dark.svg"
import patternOrange from "../../assets/images/hero-pattern-orange.svg"

import { IndustriesCardType } from "../../types"

type TopThreeBlockProps = {
  title: string
  text: string
  cards: IndustriesCardType[]
}

const TopThreeBlock = ({ title, text, cards }: TopThreeBlockProps) => {
  const cardItems = cards?.map((card, i) => (
    <div
      key={`top-three-card-${i}`}
      className="col-md-6 col-xl-4 mb-4 mb-sm-5 mb-xl-0"
    >
      <TopThreeItem
        content={card}
        isScroll={!!card.ctaLink.cached_url && card.ctaLink.linktype === "url"}
      />
    </div>
  ))

  return (
    <section className="c-topThreeBlock">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xxl-10">
            <div className="c-topThreeBlock__content">
              <div className="row">
                <div className="col-12">
                  <h2 className="section-heading section-heading--mobilexs18">
                    {title}
                  </h2>
                </div>
              </div>

              <div className="row">
                <div className="col-xl-7 col-xxl-6">
                  <p className="c-topThreeBlock__text">{text}</p>
                </div>
              </div>

              <div className="c-topThreeBlock__cards">
                <div className="row align-items-stretch">
                  {cardItems}
                  <img
                    src={patternDark}
                    className="c-topThreeBlock__cardPattern d-md-none"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <img src={patternDark} className="c-topThreeBlock__pattern" alt="" />
      <img
        src={patternOrange}
        className="c-topThreeBlock__patternOrange d-xl-none"
        alt=""
      />
    </section>
  )
}

export default TopThreeBlock
