import React from "react"
import { render, NODE_PARAGRAPH } from "storyblok-rich-text-react-renderer"

export const checkLinkUrl = link => {
  if (!link) return "/"

  if (link === "home") return "/"

  const lastCharacter = link.slice(link.length - 1)
  const firstCharachter = link.slice(0, 1)

  let finalLink = link

  if (lastCharacter !== "/" && link.length > 1) {
    finalLink = link.concat("/")
  }

  if (firstCharachter !== "/" && link.length > 1) {
    finalLink = "/" + finalLink
  }

  return finalLink
}

export const renderHeadline = (richBox, className) => {
  const title = render(richBox, {
    nodeResolvers: {
      [NODE_PARAGRAPH]: children => <h1 className={className}>{children}</h1>,
    },
  })

  return title
}

export const renderCardHeadline = (richBox, className) => {
  const title = render(richBox, {
    nodeResolvers: {
      [NODE_PARAGRAPH]: children => <h4 className={className}>{children}</h4>,
    },
  })

  return title
}

export const cutInLines = (string: string) => {
  const array = string.split("<br />")

  const linedText = array.map((str, i) => (
    <React.Fragment key={i}>
      {str}
      {array[i + 1] ? <br className="break" /> : ""}
    </React.Fragment>
  ))

  return linedText
}
