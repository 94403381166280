import React from "react"

import { render } from "storyblok-rich-text-react-renderer"

type OnDemandInfoProps = {
  title: string
  text: any
}

const OnDemandInfo = ({ title, text }: OnDemandInfoProps) => {
  const description = render(text)

  return (
    <section className="c-onDemandInfo">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xl-8 col-xxl-6">
            <div className="c-onDemandInfo__content">
              <h2 className="c-onDemandInfo__headline">{title}</h2>
              <div className="c-onDemandInfo__text">{description}</div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default OnDemandInfo
