import React from "react"

import Layout from "../components/layout/Layout"
import NothingFound from "../components/404/NothingFound"

import { SeoMetaType, ctaTarget } from "../types"

type Page404Props = {
  seoMeta: SeoMetaType
  title: string
  text: string
  ctaButton: string
  ctaLink: ctaTarget
  pathname: string
}

const Page404 = ({
  seoMeta,
  title,
  text,
  ctaButton,
  ctaLink,
  pathname,
}: Page404Props) => {
  return (
    <Layout
      contentClass="404Page"
      seoMeta={seoMeta}
      pathname={pathname}
      errorPage
    >
      <NothingFound
        title={title}
        text={text}
        ctaButton={ctaButton}
        ctaLink={ctaLink}
      />
    </Layout>
  )
}

export default Page404
