import React from "react"

import ProjectEstimateForm from "../services/ProjectEstimateForm"

import { ProjectEstimateFormProps } from "../services/ProjectEstimateForm"

type FormOverlayProps = {
  isOpen: boolean
  closeFormOverlay: () => void
  formTitle: string
  formSource: string
  form: { content: ProjectEstimateFormProps }
}

const FormOverlay = ({
  isOpen,
  closeFormOverlay,
  formTitle,
  formSource,
  form,
}: FormOverlayProps) => {
  return isOpen ? (
    <div className="c-formOverlay">
      <div className="c-formOverlay__form">
        <div className="c-formOverlay__close" onClick={closeFormOverlay} />

        <ProjectEstimateForm
          titleForm={formTitle}
          formSource={formSource}
          {...form?.content}
        />
      </div>
    </div>
  ) : null
}

export default FormOverlay
