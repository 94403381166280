import * as React from "react"
import NavDropdownMainLink from "../layout/NavDropdownMainLink"
import NavDropdownSmallItems from "../layout/NavDropdownSmallItems"

const Components = type => {
  const ComponentList = {
    "Top Nav Submenu Main Link": NavDropdownMainLink,
    "Top Nav Submenu Small Items": NavDropdownSmallItems,
  }

  if (typeof ComponentList[type] === "undefined") {
    return null
  }
  return ComponentList[type]
}

export default Components
