import React from "react"

import patternDark from "../../assets/images/hero-pattern-dark.svg"
import patternOrangeDesktop from "../../assets/images/pattern-orange-small.svg"
import patternOrange from "../../assets/images/hero-pattern-orange.svg"

type ImageHorizontalStudyProps = {
  image: string
}

const ImageHorizontalStudy = ({ image }: ImageHorizontalStudyProps) => {
  return (
    <div className="c-imageHorizontalStudy">
      <img
        src={patternOrangeDesktop}
        className="c-imageHorizontalStudy__patternOrange-small"
        alt=""
      />
      <img
        src={patternOrange}
        className="c-imageHorizontalStudy__patternOrange"
        alt=""
      />
      <img
        src={patternDark}
        className="c-imageHorizontalStudy__patternDark"
        alt=""
      />
      <div
        className="c-imageHorizontalStudy__image"
        style={{ backgroundImage: `url(${image})` }}
      />
    </div>
  )
}

export default ImageHorizontalStudy
