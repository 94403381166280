import * as React from 'react'
import StoryblokImage from './StoryblokImage'
import { ServiceCardType } from '../../types'

type ServicesSliderCardProps = {
  card: ServiceCardType
}

const ServicesSliderCard = ({ card }: ServicesSliderCardProps) => {
  return (
    <div className={`c-servicesSliderCard d-flex flex-column align-items-start`}>
      <div className="c-servicesSliderCard__imageWrapper">
        <StoryblokImage image={card?.content.iconWhite}/>
      </div>

      <h4 className="c-servicesSliderCard__title">{card?.content.title}</h4>

      <p className="c-servicesSliderCard__text">{card?.content.shortText}</p>
    </div>
  )
};

export default ServicesSliderCard
