import React from "react"
import classNames from "classnames"
import scrollTo from "gatsby-plugin-smoothscroll"
import { render } from "storyblok-rich-text-react-renderer"

import StoryblokLink from "./StoryblokLink"

import { IndustriesCardType } from "../../types"

type TopThreeItemProps = {
  content: IndustriesCardType
  isScroll?: boolean
}

const TopThreeItem = ({ content, isScroll }: TopThreeItemProps) => {
  const contentText = render(content?.text)

  const renderClassNames = () =>
    classNames("c-topThreeBlock__item", {
      "c-topThreeBlock__item--scroll":
        isScroll ||
        (content?.ctaLink.linktype === "story" && content?.ctaLink.cached_url),
    })

  const handleCardClick = () => {
    if (isScroll) scrollTo(content?.ctaLink.cached_url)
  }

  let card

  switch (content?.ctaLink?.linktype) {
    case "story":
      // in case there is story link type, the card is normal link
      card = content?.ctaLink.cached_url ? (
        <StoryblokLink to={content?.ctaLink} className={renderClassNames()}>
          <div
            className="c-topThreeBlock__hoverWrapper"
            onClick={() => handleCardClick()}
          >
            <div
              className="c-topThreeBlock__image"
              style={{ backgroundImage: `url(${content?.image.filename})` }}
            />

            <div className="c-topThreeBlock__itemContent">
              <h4 className="c-topThreeBlock__itemHeadline">
                {content?.title}
              </h4>

              <div className="c-topThreeBlock__itemText">{contentText}</div>

              <div className="c-topThreeBlock__itemLinkWrapper">
                <span className="c-topThreeBlock__itemLink hover-bcgUnderline">
                  {content?.cta}
                </span>
              </div>
            </div>
          </div>
        </StoryblokLink>
      ) : (
        // in case there is no link it is just a card with no special behaviour
        <div className={renderClassNames()}>
          <div
            className="c-topThreeBlock__hoverWrapper"
            onClick={() => handleCardClick()}
          >
            <div
              className="c-topThreeBlock__image"
              style={{ backgroundImage: `url(${content?.image.filename})` }}
            />

            <div className="c-topThreeBlock__itemContent">
              <h4 className="c-topThreeBlock__itemHeadline">
                {content?.title}
              </h4>

              <div className="c-topThreeBlock__itemText">{contentText}</div>

              <div className="c-topThreeBlock__itemLinkWrapper">
                <span className="c-topThreeBlock__itemLink hover-bcgUnderline">
                  {content?.cta}
                </span>
              </div>
            </div>
          </div>
        </div>
      )
      break
    case "url":
      // in case of url link type we use scrolls
      card = (
        <div className={renderClassNames()}>
          <div
            className="c-topThreeBlock__hoverWrapper"
            onClick={() => handleCardClick()}
          >
            <div
              className="c-topThreeBlock__image"
              style={{ backgroundImage: `url(${content?.image.filename})` }}
            />

            <div className="c-topThreeBlock__itemContent">
              <h4 className="c-topThreeBlock__itemHeadline">
                {content?.title}
              </h4>

              <div className="c-topThreeBlock__itemText">{contentText}</div>

              <div className="c-topThreeBlock__itemLinkWrapper">
                <span className="c-topThreeBlock__itemLink hover-bcgUnderline">
                  {content?.cta}
                </span>
              </div>
            </div>
          </div>
        </div>
      )
      break
    default:
      card = null
  }

  return card
}

export default TopThreeItem
