import React from "react"
import classNames from "classnames"
import { renderHeadline } from "../contentEdit"
import { BlogType } from "../../types"
import StoryblokImage from "../shared/StoryblokImage"
import heroPatternDark from "../../assets/images/hero-pattern-dark.svg"
import heroPatternOrange from "../../assets/images/hero-pattern-orange.svg"
import StoryblokLink from "../shared/StoryblokLink"

type FeaturedBlogPostHeroProps = {
  blogPost: { content: BlogType; full_slug: string }
  tag?: string
  readPostLabel?: string
}

const FeaturedBlogPostHero = ({
  blogPost,
  tag,
  readPostLabel,
}: FeaturedBlogPostHeroProps) => {
  const blog = blogPost.content
  const authors = blog?.blogAuthor.map(q => q.content) || []

  const headerImage = (
    <div className="c-blogListingHero__imageContainer">
      <div className="c-blogListingHero__imageBackground" />
      <img src={heroPatternDark} className="c-blogListingHero__patternDark" />
      <img
        src={heroPatternOrange}
        className="c-blogListingHero__patternOrange"
      />
      <StoryblokImage
        image={blog.headerImage}
        className={"c-blogListingHero__image"}
      />
    </div>
  )

  const linkObject = {
    cached_url: blogPost.full_slug,
    linktype: "story",
  }

  return (
    <section className={classNames("c-blogListingHero")}>
      <div className="container">
        <div className="row">
          <div className="col-12 col-xl-8 col-xxl-6">
            <div className="c-blogListingHero__content">
              <div className="c-blogListingHero__tag">{tag}</div>
              {renderHeadline(blog?.blogTitle, "c-blogListingHero__headline")}
              <div className="c-blogListingHero__author">
                <div className="c-blogListingHero__authorPhotosWrapper">
                  {authors.map(author => (
                    <StoryblokImage
                      image={author?.profilePhoto}
                      className={"c-blogListingHero__authorPhoto"}
                      key={author?.name}
                    />
                  ))}
                </div>
                <div className="c-blogListingHero__authorName">
                  {authors.map(q => q?.name).join(", ")}
                </div>
              </div>
              <StoryblokLink
                to={linkObject}
                className="c-blogListingHero__ctaButton btn-tertiary hover-bcgUnderline"
              >
                {readPostLabel}
              </StoryblokLink>
            </div>
          </div>
          <div className="d-md-none d-xxxl-block col-xxl-6 c-blogListingHero__imageWrapperInContainer">
            {headerImage}
          </div>
        </div>
      </div>
      <div className="d-none d-md-block d-xxxl-none c-blogListingHero__imageWrapperPulledRight">
        {headerImage}
      </div>
    </section>
  )
}

export default FeaturedBlogPostHero
