import React from "react"

import SolutionServiceCard from "./SolutionServiceCard"
import StoryblokLink from "../shared/StoryblokLink"

import { ServiceCardType, ctaTarget } from "../../types"

type SolutionServicesProps = {
  title: string
  text: string
  cards: ServiceCardType[]
  ctaButton: string
  ctaLink: ctaTarget
}

const SolutionServices = ({
  title,
  text,
  cards,
  ctaButton,
  ctaLink,
}: SolutionServicesProps) => {
  const cardItems = cards?.map((card, i) => (
    <div key={`solution-card-${i}`} className="col-sm-6 col-xl-3">
      <SolutionServiceCard
        card={card}
        classModifier={`c-solutionServices__card--position${i + 1}`}
      />
    </div>
  ))

  return (
    <section className="c-solutionServices">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xxl-10">
            <div className="c-solutionServices__box">
              <div className="row mb-3 mb-sm-5">
                <div className="col-12 mb-2 mb-sm-3">
                  <h2 className="section-heading section-heading--mobilexs18">
                    {title}
                  </h2>
                </div>
              </div>

              <div className="row pt-1">
                <div className="col-lg-10 col-xl-7 col-xxl-5">
                  <h3 className="section-subheading">{text}</h3>
                </div>
              </div>

              <div className="c-solutionServices__cards">
                <div className="row">{cardItems}</div>
              </div>

              <div className="row">
                <div className="col-12">
                  {ctaButton && ctaLink.cached_url && (
                    <StoryblokLink to={ctaLink} className="d-inline-block mt-5">
                      <span className="btn-secondary">{ctaButton}</span>
                    </StoryblokLink>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default SolutionServices
