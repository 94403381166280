import React, { useState, useEffect } from "react"
import AnimateHeight from "react-animate-height"
import classNames from "classnames"

type AutoHeightProps = {
  children: React.ReactNode
  identifier: string
}

const AutoHeight = ({ children, identifier }: AutoHeightProps) => {
  const [height, setHeight] = useState<"auto" | number>(450)

  useEffect(() => {
    setFixedHeight()

    window.addEventListener("resize", setAutoHeight)
    window.addEventListener("orientationchange", setAutoHeight)

    return () => {
      window.removeEventListener("resize", setAutoHeight)
      window.removeEventListener("orientationchange", setAutoHeight)
    }
  }, [])

  useEffect(() => {
    setAutoHeight()
  }, [children])

  const setAutoHeight = () => {
    setHeight("auto")
  }

  const setFixedHeight = () => {
    const newHeight = document.querySelector(`.${identifier}`)?.clientHeight
    setHeight(newHeight)
  }

  const renderClassNames = () =>
    classNames("c-serviceAccordion__box", identifier)

  return (
    <AnimateHeight
      height={height}
      onAnimationEnd={setFixedHeight}
      className={renderClassNames()}
    >
      {children}
    </AnimateHeight>
  )
}

export default AutoHeight
