import React from "react"

import ServiceItem from "./ServiceItem"

import { ServiceCardType } from "../../types"

type ServicesProps = {
  title: string
  text: string
  items: ServiceCardType[]
}

const Services = ({ title, text, items }: ServicesProps) => {
  const serviceItems = items?.map((item, i) => (
    <div key={`service-item-${i}`} className="col-6 col-md-4 col-lg-3">
      <ServiceItem
        content={item}
        classModifier={`c-serviceItem--position${(i % 4) + 1}`}
      />
    </div>
  ))

  return (
    <section className="c-services">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xxl-10">
            <div className="c-services__box">
              <div className="row mb-3 mb-sm-5">
                <div className="col-12 mb-2 mb-sm-3">
                  <h2 className="section-heading section-heading--mobilexs18">
                    {title}
                  </h2>
                </div>
              </div>

              <div className="row pt-1">
                <div className="col-lg-10 col-xl-7 col-xxl-5">
                  <h3 className="section-subheading">{text}</h3>
                </div>
              </div>

              <div className="c-services__cards">
                <div className="row">{serviceItems}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Services
