const THRESHOLD = 100; // magic constant

export const getHorizontalPositionInContainer = (card: HTMLElement, container: HTMLElement) => {

  const cardClientRect = card.getBoundingClientRect();
  const containerClientRect = container.getBoundingClientRect();

  let horizontal = 'middle';
  if (Math.abs(containerClientRect.left - cardClientRect.left) < THRESHOLD) {
    horizontal = 'left';
  } else if (Math.abs(containerClientRect.right - cardClientRect.right) < THRESHOLD) {
    horizontal = 'right';
  }

  return horizontal;
};
