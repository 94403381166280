import React from "react"

import Layout from "../components/layout/Layout"
import MemberEstimateHero from "../components/memberEstimate/MemberEstimateHero"
import ThreeBlock from "../components/shared/ThreeBlock"

import { SeoMetaType, StoryblokImageType, ctaTarget, ThreeCard } from "../types"
import { ProjectEstimateFormProps } from "../components/services/ProjectEstimateForm"

type MemberEstimateProps = {
  pathname: string
  seoMeta: SeoMetaType
  photo: StoryblokImageType
  description: string
  name: string
  position: string
  linkedIn: ctaTarget
  email: ctaTarget
  form: { content: ProjectEstimateFormProps }
  formTitle: string
  text: string
  blogs: ThreeCard[]
  blogCta: string
  blogCtaLink: ctaTarget
}

const MemberEstimate = ({
  pathname,
  seoMeta,
  photo,
  description,
  name,
  position,
  linkedIn,
  email,
  form,
  formTitle,
  text,
  blogs,
  blogCta,
  blogCtaLink,
}: MemberEstimateProps) => {
  return (
    <Layout
      contentClass="memberEstimatePage"
      pathname={pathname}
      seoMeta={seoMeta}
    >
      <MemberEstimateHero
        name={name}
        position={position}
        photo={photo}
        text={description}
        linkedIn={linkedIn}
        email={email}
        form={form}
        formTitle={formTitle}
      />
      {blogs?.length && (
        <ThreeBlock
          text={text}
          cards={blogs}
          cta={blogCta}
          ctaLink={blogCtaLink}
        />
      )}
    </Layout>
  )
}

export default MemberEstimate
