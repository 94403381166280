import React from "react"
import { render } from "storyblok-rich-text-react-renderer"

import StoryblokLink from "../shared/StoryblokLink"

import { Card } from "../../types"

type UseCaseCard = {
  card: Card
}

const UseCaseCard = ({ card }: UseCaseCard) => {
  const text = card?.text ? render(card.text) : ""

  return (
    <StoryblokLink
      to={card?.ctaLink}
      className="c-useCaseCard d-flex flex-column"
    >
      <div className="c-useCaseCard__hoverWrapper">
        <div
          className="c-useCaseCard__imageWrapper"
          style={{ backgroundImage: `url(${card?.image.filename})` }}
        />

        <div className="c-useCaseCard__content">
          <h4 className="c-useCaseCard__heading">{card?.title}</h4>

          <div className="c-useCaseCard__text">{text}</div>

          <span className="btn-tertiary hover-bcgUnderline">{card?.cta}</span>
        </div>
      </div>
    </StoryblokLink>
  )
}

export default UseCaseCard
