import React from "react"
import TextBlock from "./TextBlock"

type CaseBenefitsProps = {
  sectionName: string
  content: any
  show: boolean
}

const CaseBenefits = ({ sectionName, content, show }: CaseBenefitsProps) => {
  return show ? (
    <section className="c-caseBenefits">
      <TextBlock sectionName={sectionName} content={content} />
    </section>
  ) : null
}

export default CaseBenefits
