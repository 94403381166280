import React from "react"

import Layout from "../components/layout/Layout"
import Hero from "../components/shared/Hero"
import TimeLine from "../components/howItWorks/TimeLine"
import CtaBanner from "../components/shared/CtaBanner"

import {
  SeoMetaType,
  HeaderType,
  RoadmapStepType,
  CtaBannerType,
} from "../types"

type HowItWorksProps = {
  pathname: string
  seoMeta: SeoMetaType
  header: HeaderType[]
  title: string
  text: string
  steps: RoadmapStepType[]
  ctaBanner: CtaBannerType
}

const HowItWorks = ({
  pathname,
  seoMeta,
  header,
  title,
  text,
  steps,
  ctaBanner,
}: HowItWorksProps) => {
  return (
    <Layout contentClass="HowItWorksPage" seoMeta={seoMeta} pathname={pathname}>
      <Hero header={header} />
      <TimeLine title={title} text={text} steps={steps} />
      <CtaBanner ctaBanner={ctaBanner} />
    </Layout>
  )
}

export default HowItWorks
