import * as React from "react"
import NavDropdownMobileMainLink from "../layout/NavDropdownMobileMainLink"
import NavDropdownMobileSmallItems from "../layout/NavDropdownMobileSmallItems"

const Components = type => {
  const ComponentList = {
    "Top Nav Submenu Main Link": NavDropdownMobileMainLink,
    "Top Nav Submenu Small Items": NavDropdownMobileSmallItems,
  }

  if (typeof ComponentList[type] === "undefined") {
    return null
  }
  return ComponentList[type]
}

export default Components
