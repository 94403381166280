import React from "react"

import StoryblokLink from "./StoryblokLink"

import pattern from "../../assets/images/footer-pattern-orange.svg"

import { HowBannerType } from "../../types"

type HowItWorksBannerType = {
  content: HowBannerType
}

const HowItWorksBanner = ({ content }: HowItWorksBannerType) => {
  return (
    <section className="c-howItWorksBanner">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xxl-10">
            <div className="row">
              <div className="col-md-6 col-lg-5">
                <div className="c-howItWorksBanner__content">
                  <div className="row pb-3 pb-lg-5 mb-3">
                    <div className="col-12">
                      <h2 className="section-heading">
                        {content?.content?.title}
                      </h2>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-12">
                      <p className="c-howItWorksBanner__text">
                        {content?.content?.text}
                      </p>
                    </div>
                  </div>

                  <div className="row pb-2">
                    <div className="col-12">
                      <StoryblokLink
                        to={content?.content?.ctaLink}
                        className="c-howItWorksBanner__link btn-tertiary hover-bcgUnderline"
                      >
                        {content?.content?.cta}
                      </StoryblokLink>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <img
        src={pattern}
        className="c-howItWorksBanner__patternOrange"
        alt="pattern"
      />
      <div
        className="c-howItWorksBanner__image"
        style={{ backgroundImage: `url(${content?.content?.image.filename})` }}
      />
    </section>
  )
}

export default HowItWorksBanner
