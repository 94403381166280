import React from "react"
import { render } from "storyblok-rich-text-react-renderer"

import StoryblokImage from "../shared/StoryblokImage"

import patternOrange from "../../assets/images/testimonial-pattern-orange.svg"

import { StoryblokImageType } from "../../types"

type WhitepaperInfoType = {
  title: string
  text: any
  image: StoryblokImageType
}

const WhitepaperInfo = ({ title, text, image }: WhitepaperInfoType) => {
  const infoText = render(text)

  return (
    <section className="c-whitepaperInfo">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xxl-10">
            <div className="c-whitepaperInfo__content">
              <div className="row">
                <div className="col-xl-6">
                  <div className="c-whitepaperInfo__text">
                    <h2 className="c-whitepaperInfo__headline">{title}</h2>
                    <div className="c-whitepaperInfo__detail">{infoText}</div>
                  </div>
                </div>

                <div className="col-xl-6">
                  <StoryblokImage
                    image={image}
                    className="c-whitepaperInfo__image"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <img
        src={patternOrange}
        alt="pattern simplity"
        className="c-whitepaperInfo__patternOrange"
      />
      <StoryblokImage
        image={image}
        className="c-whitepaperInfo__imageOutside"
      />
    </section>
  )
}

export default WhitepaperInfo
