import React from "react"

import Layout from "../components/layout/Layout"
import WhitepaperHero from "../components/whitepapers/WhitepaperHero"
import WhitepaperInfo from "../components/whitepapers/WhitepaperInfo"
import ScrollCTA from "../components/whitepapers/ScrollCTA"
import Testimonials from "../components/home/Testimonials"
import BottomForm from "../components/whitepapers/BottomForm"

import {
  SeoMetaType,
  StoryblokImageType,
  Testimonial,
  ctaTarget,
} from "../types"
import { WhitepaperFormProps } from "../components/whitepapers/WhitepaperHeroForm"

type WhitepaperType = {
  pathname: string
  seoMeta: SeoMetaType
  title: any
  text: any
  image: StoryblokImageType
  form: { content: WhitepaperFormProps }
  formSource: string
  title2: string
  text2: any
  image2: StoryblokImageType
  whitepaperTestimonials: Testimonial[]
  scrollText: string
  scrollLabel: string
  scrollLink: ctaTarget
  docPath: ctaTarget
  docName: string
}

const Whitepaper = ({
  pathname,
  seoMeta,
  text,
  title,
  image,
  form,
  title2,
  text2,
  image2,
  whitepaperTestimonials,
  scrollText,
  scrollLabel,
  scrollLink,
  docPath,
  docName,
  formSource,
}: WhitepaperType) => {
  return (
    <Layout contentClass="WhitepaperPage" pathname={pathname} seoMeta={seoMeta}>
      <WhitepaperHero
        title={title}
        text={text}
        image={image}
        form={form}
        formSource={formSource}
        docPath={docPath?.cached_url}
        docName={docName}
      />
      <WhitepaperInfo title={title2} text={text2} image={image2} />
      <ScrollCTA text={scrollText} label={scrollLabel} link={scrollLink} />
      <Testimonials testimonials={whitepaperTestimonials} whiteBcg />
      <BottomForm
        form={form}
        docPath={docPath?.cached_url}
        docName={docName}
        formSource={formSource}
      />
    </Layout>
  )
}

export default Whitepaper
