import React from "react"

import { render } from "storyblok-rich-text-react-renderer"

type WebinarInfoProps = {
  title: string
  text: any
}

const WebinarInfo = ({ title, text }: WebinarInfoProps) => {
  const description = render(text)

  return (
    <section className="c-webinarInfo">
      <div className="container">
        <div className="row">
          <div className="col-xxl-1" />

          <div className="col-xl-6 col-xxl-5">
            <div className="c-webinarInfo__content">
              <h2 className="c-webinarInfo__title">{title}</h2>
              <div className="c-webinarInfo__description">{description}</div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default WebinarInfo
