import React from "react"

import StoryblokImage from "../shared/StoryblokImage"

import { ServiceCardType } from "../../types"

type ServiceItemProps = {
  content: ServiceCardType
  classModifier: string
}

const ServiceItem = ({ content, classModifier }: ServiceItemProps) => {

  if (!content || !content.content) {
    return null;
  }

  return (
    <div className={`c-serviceItem ${classModifier}`}>
      <div className="c-serviceItem__iconWrapper">
        <StoryblokImage image={content?.content.iconWhite} />
      </div>

      <p className="c-serviceItem__name">{content?.content.title}</p>
    </div>
  )
}

export default ServiceItem
