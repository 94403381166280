import React from "react"

import Layout from "../components/layout/Layout"
import Hero from "../components/shared/Hero"
import TopThreeBlock from "../components/shared/TopThreeBlock"
import SolutionCases from "../components/solutionsOverview/SolutionCases"
import SolutionServices from "../components/solutionsOverview/SolutionServices"
import CtaBanner from "../components/shared/CtaBanner"

import {
  SeoMetaType,
  HeaderType,
  IndustriesCardType,
  FiftyFiftyCardType,
  ServiceCardType,
  ctaTarget,
  CtaBannerType,
} from "../types"

type SolutionsOverviewProps = {
  seoMeta: SeoMetaType
  header: HeaderType[]
  pathname: string
  industriesTitle: string
  industriesText: string
  industriesCards: IndustriesCardType[]
  useCasesTitle: string
  useCases: FiftyFiftyCardType[]
  servicesTitle: string
  servicesText: string
  servicesCards: ServiceCardType[]
  servicesCtaButton: string
  servicesCtaLink: ctaTarget
  ctaBanner: CtaBannerType
}

const SolutionsOverview = ({
  seoMeta,
  header,
  pathname,
  industriesTitle,
  industriesText,
  industriesCards,
  useCasesTitle,
  useCases,
  servicesTitle,
  servicesText,
  servicesCards,
  servicesCtaButton,
  servicesCtaLink,
  ctaBanner,
}: SolutionsOverviewProps) => {
  return (
    <Layout
      contentClass="SolutionsOverviewPage"
      seoMeta={seoMeta}
      pathname={pathname}
    >
      <Hero header={header} />
      <TopThreeBlock
        title={industriesTitle}
        text={industriesText}
        cards={industriesCards}
      />
      <SolutionCases title={useCasesTitle} useCases={useCases} />
      <SolutionServices
        title={servicesTitle}
        text={servicesText}
        cards={servicesCards}
        ctaButton={servicesCtaButton}
        ctaLink={servicesCtaLink}
      />
      <CtaBanner ctaBanner={ctaBanner} />
    </Layout>
  )
}

export default SolutionsOverview
