import React from "react"

import BenefitItem from "./BenefitItem"

import patternDark from "../../assets/images/hero-pattern-dark.svg"

import { BenefitCardType } from "../../types"

type BenefitsProps = {
  title: string
  text: string
  subText?: string
  cards: BenefitCardType[]
}

const Benefits = ({ title, text, cards, subText }: BenefitsProps) => {
  const cardItems = cards?.map((card, i) => (
    <div key={`benefit-card-${i}`} className="col-md-6 col-xl-4">
      <BenefitItem content={card} />
    </div>
  ))

  return (
    <section className="c-benefits">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xxl-10">
            <div className="c-benefits__box">
              <div className="row mb-5">
                <div className="col-12 mb-2 mb-sm-5">
                  <h2 className="section-heading section-heading--mobilexs18">
                    {title}
                  </h2>
                </div>
              </div>

              <div className="row pt-2">
                <div className="col-lg-10 col-xl-7 col-xxl-6">
                  <h3 className="section-subheading">{text}</h3>
                </div>
              </div>

              {subText && <div className="row pt-5 mt-5">
                <div className="col-lg-10 col-xl-7 col-xxl-6">
                  <h3 className="section-subText">{subText}</h3>
                </div>
              </div>}

              <div className="c-benefits__content">
                <div className="row">{cardItems}</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <img src={patternDark} className="c-benefits__pattern" alt="" />
    </section>
  )
}

export default Benefits
