import React from "react"

import FiftyFiftyBlockBase from "../shared/FiftyFiftyBlockBase"
import { StoryblokImageType } from "../../types"

type SimplityEuropeFiftyFiftyBlockProps = {
  contactImage: StoryblokImageType;
  contactTitle: string;
  companyName: string;
  contactText: string;
}

const SimplityEuropeFiftyFiftyBlock = ({
                                         contactImage,
                                         contactTitle,
                                         companyName,
                                         contactText
                                       }: SimplityEuropeFiftyFiftyBlockProps) => {
  return (
    <div className="c-simplityEuropeFiftyFifty">
      <FiftyFiftyBlockBase
        backgroundImageUrl={contactImage?.filename}
      >
        <div>
          <h2 className="section-heading">{contactTitle}</h2>
          <h2 className="c-simplityEuropeFiftyFifty__name">{companyName}</h2>
          <p>{contactText.split("\n").map(line => (<>{line}<br /></>))}</p>
        </div>
      </FiftyFiftyBlockBase>
    </div>
  )
}

export default SimplityEuropeFiftyFiftyBlock
