import React from "react"

import patternDark from "../../assets/images/hero-pattern-dark.svg"
import patternOrange from "../../assets/images/hero-pattern-orange.svg"

type HeroImageHorizontalCommonProps = {
  image: string
}

const HeroImageHorizontalCommon = ({
  image,
}: HeroImageHorizontalCommonProps) => {
  return (
    <div className="c-imageHorizontal">
      <img
        src={patternOrange}
        className="c-imageHorizontal__patternOrange"
        alt=""
      />
      <img
        src={patternDark}
        className="c-imageHorizontal__patternDark"
        alt=""
      />
      <div
        className="c-imageHorizontal__image"
        style={{ backgroundImage: `url(${image})` }}
      />
    </div>
  )
}

export default HeroImageHorizontalCommon
