import { useCallback, useRef, useState } from 'react';
import { getHorizontalPositionInContainer } from './getHorizontalPositionInContainer';

export const useExpandableCard = () => {

  const container = useRef<HTMLDivElement>(null);
  const [expandedCard, setExpandedCard] = useState<number>();
  const [expandedDirection, setExpandedDirection] = useState<'left' | 'right'>('right');

  const isExpanded = useCallback((idx) => {
    return expandedCard === idx;
  }, [expandedCard]);

  const open = useCallback((idx, elm: HTMLElement) => {
    setExpandedCard(idx);
    setExpandedDirection(getHorizontalPositionInContainer(elm, container.current) === 'right' ? 'left' : 'right')
  }, [setExpandedCard, setExpandedDirection, container]);

  const close = useCallback(() => {
    setExpandedCard(null);
  }, [setExpandedCard]);

  return {
    expandedDirection,
    isCardExpanded: isExpanded,
    openCard: open,
    closeCard: close,
    cardsContainer: container
  };
};
