import React from "react"

type YoutubeOverlayProps = {
  link: string
  isOpen: boolean
  closeYoutubeOverlay: () => void
}

const YoutubeOverlay = ({
  link,
  isOpen,
  closeYoutubeOverlay,
}: YoutubeOverlayProps) => {
  return isOpen ? (
    <div className="c-youtubeOverlay">
      <div className="c-youtubeOverlay__wrapper">
        <iframe
          className="c-youtubeOverlay__iframe"
          src={link}
          frameBorder="0"
          allow="autoplay; encrypted-media"
          title="webinar video"
          allowFullScreen
          width="1280"
          height="720"
          style={{
            maxWidth: "100%",
            maxHeight: "100%",
          }}
        />
        <div
          className="c-youtubeOverlay__close"
          onClick={() => closeYoutubeOverlay()}
        />
      </div>
    </div>
  ) : null
}

export default YoutubeOverlay
