import React, { useState } from "react"
import { BlogType, CtaBannerType, SeoMetaType } from "../types"
import { graphql, useStaticQuery } from "gatsby"
import Layout from "../components/layout/Layout"
import FeaturedBlogPostHero from "../components/blog/FeaturedBlogPostHero"
import CtaBanner from "../components/shared/CtaBanner"
import ThreeBlock from "../components/shared/ThreeBlock"

type BlogOverviewPageProps = {
  ctaBanner: CtaBannerType
  featuredPost: { content: BlogType }
  featuredPostCtaButton: string
  featuredPostTag: string
  listingTitle: string
  listingSubtitle: string
  loadMoreBtnLabel: string
  numberOfVisibleBlogs: number
  seoMeta: SeoMetaType
  pathname: string
}

const useLoadBlogPosts = (): {
  full_slug: string
  published_at: string | null
  content: BlogType
}[] => {
  try {
    const queryResult = useStaticQuery(
      graphql`
        query BlogListingPosts {
          allStoryblokEntry(filter: { field_component: { eq: "Blog" } }) {
            nodes {
              full_slug
              published_at
              content
            }
          }
        }
      `
    )

    return queryResult.allStoryblokEntry.nodes.map(node => {
      return {
        ...node,
        content: JSON.parse(node.content),
      }
    })
  } catch (e) {
    console.error(
      "Static Query for Blog Overview failed!!! Falling back to dummy data."
    )
    return []
  }
}

const BlogListingPage = ({
  loadMoreBtnLabel,
  ctaBanner,
  featuredPost,
  featuredPostCtaButton,
  featuredPostTag,
  listingTitle,
  listingSubtitle,
  seoMeta,
  pathname,
  numberOfVisibleBlogs,
}: BlogOverviewPageProps) => {
  const [isAllVisible, setIsAllVisible] = useState(false)
  const allBlogPosts = useLoadBlogPosts()
  const allPublishedBlogPosts = allBlogPosts.filter(q => !!q.published_at)
  let visibleBlogPosts = isAllVisible
    ? allPublishedBlogPosts
    : allPublishedBlogPosts.slice(0, numberOfVisibleBlogs)
  visibleBlogPosts.sort((a, b) =>
    a.published_at && b.published_at
      ? -a.published_at.localeCompare(b.published_at)
      : 0
  )
  const featuredBlogPost =
    allBlogPosts.find(q => q.content?._uid === featuredPost?.content?._uid) ||
    allPublishedBlogPosts[0]

  return (
    <Layout
      contentClass="BlogListingPage"
      seoMeta={seoMeta}
      pathname={pathname}
    >
      {featuredBlogPost && (
        <FeaturedBlogPostHero
          blogPost={featuredBlogPost}
          tag={featuredPostTag}
          readPostLabel={featuredPostCtaButton}
        />
      )}

      <ThreeBlock
        title={listingTitle}
        text={listingSubtitle}
        cards={visibleBlogPosts}
        cardCtaLabel={"Show me the post"}
        resourcesType={true}
      />

      {numberOfVisibleBlogs < allPublishedBlogPosts.length && !isAllVisible && (
        <div className="c-blogListing__showMore text-center">
          <span
            onClick={() => setIsAllVisible(true)}
            className="hover-bcgUnderline"
          >
            {loadMoreBtnLabel}
          </span>
        </div>
      )}

      {ctaBanner && <CtaBanner ctaBanner={ctaBanner} />}
    </Layout>
  )
}

export default BlogListingPage
