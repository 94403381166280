import React from 'react'
import classNames from 'classnames'
import StoryblokLink from './StoryblokLink'
import pattern from '../../assets/images/footer-pattern-orange.svg'
import { CtaBannerType } from '../../types'
import CtaNewsletterBanner from './CtaNewsletterBanner';

type CtaBannerProps = {
  ctaBanner: CtaBannerType
}

const CtaBanner = ({ ctaBanner }: CtaBannerProps) => {

  if (!ctaBanner || !ctaBanner.content) {
    return null;
  }

  if (ctaBanner.content.component === 'Newsletter CTA Banner') {
    return <CtaNewsletterBanner {...ctaBanner.content}/>;
  }

  const renderClassNames = () =>
    classNames('c-ctaBanner', {
      'c-ctaBanner--noHeadline': !ctaBanner?.content.bannerTitle,
    })

  return (
    <section className={renderClassNames()}>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-10 col-xl-8 col-xxl-6">
            <div className="c-ctaBanner__body d-flex flex-column align-items-center">
              {ctaBanner?.content.bannerTitle && (
                <h4 className="c-ctaBanner__heading text-center">
                  {ctaBanner?.content.bannerTitle}
                </h4>
              )}

              <p className="c-ctaBanner__text text-center pb-5 mb-3">
                {ctaBanner?.content.bannerText}
              </p>

              <StoryblokLink
                to={ctaBanner?.content.bannerCtaLink}
                className="btn-primary"
              >
                {ctaBanner?.content.bannerCtaButton}
              </StoryblokLink>

              <img src={pattern} className="c-ctaBanner__pattern" alt=""/>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default CtaBanner
