import React from "react"

import StoryblokImage from "../shared/StoryblokImage"

import PlayIcon from "../../assets/images/icons/youtube-play.svg"
import heroPatternDark from "../../assets/images/hero-pattern-dark.svg"
import heroPatternOrange from "../../assets/images/footer-pattern-orange.svg"
import homePatternOrange from "../../assets/images/hero-pattern-orange.svg"

import { renderHeadline } from "../contentEdit"

import { BlogAuthorType, StoryblokImageType } from "../../types"

type OnDemandWebinarHeoType = {
  title: any
  watchTime: string
  presenters: { content: BlogAuthorType }[]
  youtubeImage: StoryblokImageType
  openYoutubeOverlay: () => void
}

const OnDemandWebinarHero = ({
  title,
  watchTime,
  presenters,
  youtubeImage,
  openYoutubeOverlay,
}: OnDemandWebinarHeoType) => {
  const headline = renderHeadline(title, "c-onDemandWebinarHero__headline")

  const webinarPresenters = presenters.map((presenter, i) => (
    <div
      key={`on-demand-webinar-${i}`}
      className="c-onDemandWebinarHero__presenter d-flex align-items-center"
    >
      <StoryblokImage
        image={presenter?.content?.profilePhoto}
        className="c-onDemandWebinarHero__presenter-photo"
      />

      <span className="c-onDemandWebinarHero__presenter-name">
        {presenter?.content?.name}
      </span>
    </div>
  ))

  return (
    <section className="c-onDemandWebinarHero">
      <div className="container">
        <div className="row justify-content-between">
          <div className="col-xxl-6 col-xxxl-5">
            <div className="c-onDemandWebinarHero__content">
              {headline}
              <p className="c-onDemandWebinarHero__watchTime">{watchTime}</p>
              <div className="d-flex flex-wrap flex-column flex-sm-row c-onDemandWebinarHero__people">
                {webinarPresenters}
              </div>
            </div>
          </div>

          <div className="col-12 position-static">
            <div
              className="c-onDemandWebinarHero__wrapper"
              onClick={() => openYoutubeOverlay()}
            >
              <StoryblokImage
                image={youtubeImage}
                className="c-onDemandWebinarHero__image"
              />
              <PlayIcon className="c-onDemandWebinarHero__play" />
            </div>
          </div>
        </div>
      </div>

      <img
        src={heroPatternDark}
        alt="pattern dark"
        className="c-onDemandWebinarHero__patternDark"
      />
      <img
        src={heroPatternOrange}
        alt="pattern orange"
        className="c-onDemandWebinarHero__patternOrange"
      />
      <img
        src={homePatternOrange}
        alt="pattern orange"
        className="c-onDemandWebinarHero__patternOrangeBig"
      />
    </section>
  )
}

export default OnDemandWebinarHero
