import * as React from 'react'
import Layout from '../components/layout/Layout'
import Hero from '../components/shared/Hero'
import CtaBanner from '../components/shared/CtaBanner'
import { BenefitCardType, ClientsLogos, CtaBannerType, FiftyFiftyCardType, HeaderType, Resource, SeoMetaType, ServiceCardType, Testimonial, } from '../types'
import Benefits from '../components/solutionDetail/Benefits';
import Learn from '../components/shared/Learn';
import SolutionCases from '../components/solutionsOverview/SolutionCases';
import Recognitions from '../components/aboutUs/Recognitions';
import GetInTouchForm, { GetInTouchFormProps } from '../components/getInTouch/GetInTouchForm';
import patternOrange from '../assets/images/footer-pattern-orange.svg';
import patternDark from '../assets/images/hero-pattern-dark2.svg';
import ServicesSlider from '../components/shared/ServicesSlider';
import Testimonials from "../components/home/Testimonials";

type LandingPageType = {
  seoMeta: SeoMetaType
  pathname: string
  header: HeaderType[]

  benefitsTitle: string
  benefitsSubTitle: string
  benefitsText: string
  benefitsCards: BenefitCardType[]

  caseStudiesTitle: string
  caseStudiesSubTitle: string
  caseStudiesCards: Resource[]

  ctaBanner: CtaBannerType

  servicesTitle: string
  servicesSubTitle: string
  servicesCards: ServiceCardType[]

  useCases: FiftyFiftyCardType[]
  useCasesTitle: string

  logosTitle: string
  logosSubtitle: string
  logos: ClientsLogos[]

  lpTestimonials: Testimonial[]

  form: { content: GetInTouchFormProps }
}

const LandingPage = ({
                       seoMeta,
                       pathname,
                       header,
                       benefitsCards,
                       benefitsSubTitle,
                       benefitsText,
                       benefitsTitle,
                       caseStudiesCards,
                       caseStudiesSubTitle,
                       caseStudiesTitle,
                       ctaBanner,
                       servicesCards,
                       servicesSubTitle,
                       servicesTitle,
                       useCases,
                       useCasesTitle,
                       logos,
                       logosSubtitle,
                       logosTitle,
                       lpTestimonials,
                       form,
                     }: LandingPageType) => {

  return (
    <Layout contentClass="LandingPage" seoMeta={seoMeta} pathname={pathname}>
      <Hero header={header}/>

      {benefitsCards && benefitsCards.length > 0 && <Benefits
        title={benefitsTitle}
        text={benefitsSubTitle}
        cards={benefitsCards}
        subText={benefitsText}
      />}

      {caseStudiesCards && caseStudiesCards.length > 0 && <Learn
        title={caseStudiesTitle}
        text={caseStudiesSubTitle}
        resource={caseStudiesCards}
      />}

      <CtaBanner ctaBanner={ctaBanner}/>

      {servicesCards && servicesCards.length > 0 && <ServicesSlider
        title={servicesTitle}
        text={servicesSubTitle}
        items={servicesCards}
      />}

      {useCases && useCases.length > 0 && <SolutionCases
        title={useCasesTitle}
        useCases={useCases}
        alignFirstRight={true}
      />}

      {logos && logos.length > 0 && <Recognitions
        title={logosTitle}
        text={logosSubtitle}
        logos={logos}
      />}

      <Testimonials testimonials={lpTestimonials}/>

      {form && <div className="LandingPage__form">
        <img
          src={patternOrange}
          className="LandingPage__formPatternOrange"
          alt=""
        />
        <img
          src={patternDark}
          className="LandingPage__formPatternDark"
          alt=""
        />
        <GetInTouchForm {...form.content} />
      </div>}
    </Layout>
  )
};

export default LandingPage
