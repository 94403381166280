import React, { useRef } from "react"
import classNames from "classnames"

import ResourceSlider from "../resources/ResourceSlider"

import PrevArrow from "../../assets/images/icons/prev-arrow-resource.svg"
import NextArrow from "../../assets/images/icons/next-arrow-resource.svg"

import {
  ThreeCardCaseStudySelection,
  ThreeCardReportSelection,
  ThreeCardWebinarSelection,
  OnDemandWebinarSelection,
} from "../../types"

type ResourceContentType = {
  cards:
    | ThreeCardCaseStudySelection[]
    | ThreeCardReportSelection[]
    | ThreeCardWebinarSelection[]
    | OnDemandWebinarSelection[]
  isCaseStudy: boolean
  isReport: boolean
  isWebinar: boolean
  isOnDemand: boolean
  ctaText: string
}

const ResourceContent = ({
  cards,
  isCaseStudy,
  isReport,
  isWebinar,
  isOnDemand,
  ctaText,
}: ResourceContentType) => {
  const sliderRef = useRef(null)

  const showNext = () => {
    sliderRef.current.slickNext()
  }

  const showPrev = () => {
    sliderRef.current.slickPrev()
  }

  const buttonClasses = () =>
    classNames("c-resourceContent__buttons", "justify-content-end", {
      "c-resourceContent__buttons--three": cards.length === 3,
      "c-resourceContent__buttons--two": cards.length === 2,
      "c-resourceContent__buttons--one": cards.length === 1,
    })

  return (
    <div className="c-resourceContent">
      <ResourceSlider
        cards={cards}
        isCaseStudy={isCaseStudy}
        isReport={isReport}
        isWebinar={isWebinar}
        isOnDemand={isOnDemand}
        ctaText={ctaText}
        forwardedRef={sliderRef}
      />
      <div className={buttonClasses()}>
        <button onClick={showPrev} className="c-resourceContent__button">
          <div className="c-resourceContent__hoverButton d-flex justify-content-center align-items-center">
            <PrevArrow />
          </div>
        </button>

        <button onClick={showNext} className="c-resourceContent__button">
          <div className="c-resourceContent__hoverButton d-flex justify-content-center align-items-center">
            <NextArrow />
          </div>
        </button>
      </div>
    </div>
  )
}

export default ResourceContent
