import React from 'react'
import classNames from 'classnames'
import { render } from 'storyblok-rich-text-react-renderer'
import StoryblokImage from '../shared/StoryblokImage'

import { ServiceCardType } from '../../types'

type ServiceItemType = {
  card: ServiceCardType;
  isExpanded?: boolean;
  onClick?: (elm: HTMLElement) => void;
  onClose?: () => void;
  expandDirection: 'left' | 'right';
}

const ServiceCard = ({ card, isExpanded, onClick, onClose, expandDirection = 'right' }: ServiceItemType) => {

  if (!card || !card.content) {
    return null;
  }

  const { icon, shortText, longText, ctaButton1, ctaButton2, title } = card.content;

  const className = classNames('c-serviceCard', {
    'c-serviceCard--hasLongText': !!longText,
    'c-serviceCard--expanded': isExpanded,
    'c-serviceCard--expandToLeft': expandDirection === 'left',
  });

  const handleOnClose = (e: React.MouseEvent) => {
    e.stopPropagation();
    onClose();
  };

  const handleOnExpand = (e: React.MouseEvent) => {
    onClick(e.currentTarget as HTMLElement);
  };

  const iconAndTitle = (
    <>
      <div className="c-serviceCard__iconWrapper">
        <StoryblokImage image={icon}/>
      </div>
      <h4 className="c-serviceCard__title">{title}</h4>
    </>
  );

  return (
    <div className={className} onClick={handleOnExpand}>
      <div className="c-serviceCard__content">
        {iconAndTitle}

        <div className="c-serviceCard__text c-serviceCard__text--shortText">
          {shortText}
        </div>

        {longText && <div className="c-serviceCard__itemLinkWrapper d-flex align-items-end flex-grow-1">
          <span className="c-serviceCard__itemLink hover-bcgUnderline">
            {ctaButton1}
          </span>
        </div>}
      </div>

      {longText && <div className="c-serviceCard__expandedContent">
        {iconAndTitle}

        <div className="c-serviceCard__closeBtn" onClick={handleOnClose}>⨉</div>

        <div className="c-serviceCard__text c-serviceCard__text--longText">
          {render(longText)}
        </div>

        <div className="c-serviceCard__itemLinkWrapper d-flex align-items-end flex-grow-1">
          <span className="c-serviceCard__itemLink hover-bcgUnderline" onClick={handleOnClose}>
            {ctaButton2}
          </span>
        </div>
      </div>}
    </div>
  )
};

export default ServiceCard
