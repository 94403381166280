import React from "react"

import LearnComponents from "../componentKeys/learnComponents"

import heroPatternDark from "../../assets/images/hero-pattern-dark.svg"

import { Resource } from "../../types"

type LearnProps = {
  title: string
  text?: string
  resource: Resource[]
}

const Learn = ({ title, text, resource }: LearnProps) => {
  const learnItems = resource?.map((item, i) => (
    <div key={`learn-item-${i}`} className="col-lg-6">
      {React.createElement(LearnComponents(item?.content?.component), {
        ...item,
      })}
    </div>
  ))

  return (
    <section className="c-learn">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xxl-10">
            <div className="c-learn__box">
              <div className="row mb-5">
                <div className="col-12 mb-2 mb-sm-3">
                  <h2 className="section-heading">{title}</h2>
                </div>
              </div>

              <div className="row pt-1">
                <div className="col-lg-10 col-xl-8 col-xxl-6">
                  <h3 className="section-subheading">{text}</h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="c-learn__content">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xxl-10">
              <div className="c-learn__box">
                <div className="row">{learnItems}</div>
              </div>
            </div>
          </div>
        </div>

        <img src={heroPatternDark} className="c-learn__pattern" alt="" />
      </div>
    </section>
  )
}

export default Learn
