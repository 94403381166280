import React from "react"
import classNames from "classnames"

import pattern from "../../assets/images/hero-pattern-dark.svg"

type FiftyFiftyBlockBaseProps = {
  backgroundImageUrl: string;
  rightAlign?: boolean
  isLast?: boolean
  portrait?: boolean
  displayPatterns?: boolean
  children: React.ReactNode
}

const FiftyFiftyBlockBase = ({
                           backgroundImageUrl,
                           rightAlign,
                           isLast,
                           portrait,
                           displayPatterns,
                           children,
                         }: FiftyFiftyBlockBaseProps) => {
  const renderClassNames = () =>
    classNames("c-fiftyFiftyBlock", {
      "c-fiftyFiftyBlock--right": rightAlign,
      "c-fiftyFiftyBlock--last": isLast,
      "c-fiftyFiftyBlock--portrait": portrait
    })

  return (
    <div className={renderClassNames()}>
      <div className="container">
        <div className="c-fiftyFiftyBlock__row row">
          <div className="col-md-4 col-lg-6" />

          <div className="col-md-8 col-lg-6">
            <div className="c-fiftyFiftyBlock__content d-flex flex-column">
              {children}
            </div>
          </div>
        </div>
      </div>

      {displayPatterns && (
        <>
          <img src={pattern} className="c-fiftyFiftyBlock__pattern" alt="" />
          {isLast && (
            <img src={pattern} className="c-fiftyFiftyBlock__pattern2" alt="" />
          )}
          <img
            src={pattern}
            className="c-fiftyFiftyBlock__patternMobile d-sm-none"
            alt=""
          />
        </>)}
      <div
        className="c-fiftyFiftyBlock__image"
        style={{ backgroundImage: `url(${backgroundImageUrl})` }}
      />
    </div>
  )
}

export default FiftyFiftyBlockBase
