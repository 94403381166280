import React from 'react'
import classNames from 'classnames';

import StoryblokImage from '../shared/StoryblokImage'

import { StoryblokImageType } from '../../types'
import { isValidEmail } from '../../utils';
import { useNewsletterSignup } from "../../newsletterSignup/newsletterSignup"

type FooterTopProps = {
  slogan: string
  logo: StoryblokImageType
  newsletterHeadline: string
  newsletterPlaceholder: string
  newsletterEnabled: boolean
}

const FooterTop = ({ slogan, logo, newsletterEnabled = true, newsletterHeadline, newsletterPlaceholder }: FooterTopProps) => {
  const {
    email,
    setEmail,
    doSubmit,
    isFetching,
  } = useNewsletterSignup('Footer Newsletter Signup');

  const subscribe = () => {
    if (!isFetching && isValidEmail(email)) {
      doSubmit();
    }
  };

  const handleNewsletterInputChange = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      subscribe();
    } else {
      setEmail(e.target.value);
    }
  };

  return (
    <div className="row footer__top">
      <div className="col-sm-12 col-lg-4 col-xl-3">
        <StoryblokImage image={logo} className="footer__logo"/>
        <p className="footer__top-text">{slogan}</p>
      </div>
      <div className="col-sm-12 col-lg-4 col-xl-3">
        {newsletterEnabled && <div className="footer__newsletter">
          <span className="footer__newsletterHeader">{newsletterHeadline}</span>
          <input
            className={'footer__newsletterInput'}
            name={'footer__newsletter'}
            type="email"
            placeholder={newsletterPlaceholder}
            onKeyUp={handleNewsletterInputChange}
          />
          <span
            className={classNames('footer__newsletterSubmitBtn', { 'footer__newsletterSubmitBtn--disabled': !isValidEmail(email) || isFetching })}
            onClick={subscribe}
          />
        </div>}
      </div>
    </div>
  )
}

export default FooterTop
