import React from "react"

import StoryblokImage from "../shared/StoryblokImage"
import StoryblokLink from "../shared/StoryblokLink"
import ProjectEstimateForm from "../services/ProjectEstimateForm"

import LinkedInIcon from "../../assets/images/icons/linkedin.svg"
import MailIcon from "../../assets/images/icons/mail.svg"

import patternDark from "../../assets/images/hero-pattern-dark.svg"
import patternOrange from "../../assets/images/footer-pattern-orange.svg"

import { StoryblokImageType, ctaTarget } from "../../types"
import { ProjectEstimateFormProps } from "../services/ProjectEstimateForm"

type MemberEstimateHeroProps = {
  photo: StoryblokImageType
  text: string
  name: string
  position: string
  linkedIn: ctaTarget
  email: ctaTarget
  form: { content: ProjectEstimateFormProps }
  formTitle: string
}

const MemberEstimateHero = ({
  name,
  position,
  photo,
  text,
  linkedIn,
  email,
  form,
  formTitle,
}: MemberEstimateHeroProps) => {
  return (
    <section className="c-memberEstimateHero">
      <div className="container">
        <div className="row">
          <div className="col-xxl-8">
            <div className="c-memberEstimateHero__content d-flex flex-column flex-lg-row justify-content-between">
              <div className="c-memberEstimateHero__imageWrapper">
                <StoryblokImage
                  image={photo}
                  className="c-memberEstimateHero__image"
                />
              </div>

              <div className="c-memberEstimateHero__text">
                <h1 className="c-memberEstimateHero__title">{name}</h1>
                <p className="c-memberEstimateHero__position">{position}</p>
                <div className="d-flex">
                  <StoryblokLink
                    to={linkedIn}
                    className="c-memberEstimateHero__linkedin"
                  >
                    <LinkedInIcon />
                  </StoryblokLink>
                  <StoryblokLink
                    to={email}
                    className="c-memberEstimateHero__mail"
                  >
                    <MailIcon />
                  </StoryblokLink>
                </div>
                <p className="c-memberEstimateHero__description">{text}</p>
              </div>
            </div>
          </div>

          <div className="col-xxl-4">
            <div className="c-memberEstimateHero__form">
              <ProjectEstimateForm
                formSource={name}
                titleForm={formTitle}
                {...form?.content}
              />
            </div>
          </div>
        </div>
      </div>

      <img
        src={patternDark}
        className="c-memberEstimateHero__patternDark"
        alt="simplity project estimate"
      />

      <img
        src={patternOrange}
        className="c-memberEstimateHero__patternOrange"
        alt="simplity project estimate"
      />

      <img
        src={patternOrange}
        className="c-memberEstimateHero__patternOrangeMobile"
        alt="simplity project estimate"
      />
    </section>
  )
}

export default MemberEstimateHero
