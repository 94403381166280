import React from "react"
import classNames from "classnames"

import StoryblokImage from "../shared/StoryblokImage"

import { TimeLineStepType } from "../../types"

type RoadmapProps = {
  title: string
  text: string
  steps: TimeLineStepType[]
}

const TimeLine = ({ title, text, steps }: RoadmapProps) => {
  const stepClassNames = i =>
    classNames("c-timeline__step", {
      "c-timeline__step--left": i % 2,
      "c-timeline__step--last": i + 1 === steps.length,
    })

  const stepItems = steps?.map((step, i) => (
    <div className={stepClassNames(i)} key={`how-it-works-step-${i}`}>
      <div className="row">
        <div className="col-xl-6">
          <div className="c-timeline__step-half">
            <span className="c-timeline__step-number">{i + 1}</span>
            <div className="row">
              <div className="col-9 col-md-10">
                <div className="c-timeline__step-content">
                  <h3 className="c-timeline__step-title">{step?.title}</h3>

                  <p className="c-timeline__step-text">{step?.text}</p>

                  {step?.technologyLogos?.length ? (
                    <ul className="c-timeline__step-logos d-flex flex-wrap justify-content-md-center align-items-center">
                      {step.technologyLogos.map((logo, i) => (
                        <li
                          className="c-timeline__step-logoItem d-flex justify-content-center align-items-center"
                          key={`timeline-logo-${i}`}
                        >
                          <StoryblokImage image={logo?.content?.logo} />
                        </li>
                      ))}
                    </ul>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  ))

  return (
    <section className="c-timeline">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xxl-10">
            <div className="c-timeline__content">
              <div className="row">
                <div className="col-12 pb-5 mb-5 mb-xxl-3">
                  <h2 className="section-heading">{title}</h2>
                </div>
              </div>

              <div className="row">
                <div className="col-12 col-xl-8 col-xxl-6">
                  <h3 className="section-subheading">{text}</h3>
                </div>
              </div>

              <div className="c-timeline__line">{stepItems}</div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default TimeLine
