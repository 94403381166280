import React from "react"
import classNames from "classnames"

import ResourceContent from "./ResourceContent"

import patternDark from "../../assets/images/hero-pattern-dark.svg"
import patternOrange from "../../assets/images/hero-pattern-orange-small.svg"

import {
  ThreeCardCaseStudySelection,
  ThreeCardReportSelection,
  ThreeCardWebinarSelection,
  OnDemandWebinarSelection,
} from "../../types"

type ResourceListingType = {
  title: string
  text: string
  cards:
    | ThreeCardCaseStudySelection[]
    | ThreeCardReportSelection[]
    | ThreeCardWebinarSelection[]
    | OnDemandWebinarSelection[]
  isCaseStudy?: boolean
  isReport?: boolean
  isWebinarUpcoming?: boolean
  isOnDemandWebinar?: boolean
  showHeadline?: boolean
  ctaText: string
}

const ResourceListing = ({
  title,
  text,
  cards,
  isCaseStudy,
  isReport,
  isWebinarUpcoming,
  isOnDemandWebinar,
  showHeadline,
  ctaText,
}: ResourceListingType) => {
  const renderClassNames = () =>
    classNames("c-resourceListing", {
      "c-resourceListing--webinar": isWebinarUpcoming,
      "c-resourceListing--report": isReport,
      "c-resourceListing--short": cards?.length < 3,
    })

  return cards?.length ? (
    <section
      className={renderClassNames()}
      id={isReport ? "whitepapers" : isCaseStudy ? "case-studies" : "webinars"}
    >
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xxl-10">
            <div className="c-resourceListing__box">
              {!showHeadline && (
                <div className="row mb-5">
                  <div className="col-12 mb-2 mb-sm-3">
                    <h2 className="section-heading">{title}</h2>
                  </div>
                </div>
              )}

              {text && (
                <div className="row mb-0 mb-lg-3 mb-xxl-3">
                  <div className="col-lg-9 col-xl-6 pb-0 pb-xl-4 pb-xxl-0">
                    <h3 className="section-subheading">{text}</h3>
                  </div>
                </div>
              )}

              <div className="c-resourceListing__items">
                <div className="row">
                  <div className="col-12">
                    <ResourceContent
                      isCaseStudy={!!isCaseStudy}
                      isReport={!!isReport}
                      isWebinar={!!isWebinarUpcoming}
                      isOnDemand={!!isOnDemandWebinar}
                      cards={cards}
                      ctaText={ctaText}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <img
        src={patternOrange}
        className="c-resourceListing__patternOrange"
        alt=""
      />
      <img
        src={patternDark}
        className="c-resourceListing__patternDark"
        alt=""
      />
    </section>
  ) : null
}

export default ResourceListing
