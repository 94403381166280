import React from "react"

import StoryblokImage from "../shared/StoryblokImage"

import heroPatternDark from "../../assets/images/hero-pattern-dark.svg"
import heroPatternOrange from "../../assets/images/footer-pattern-orange.svg"

import { renderHeadline } from "../contentEdit"

import { BlogAuthorType, StoryblokImageType } from "../../types"

type WebinarHeroProps = {
  title: string
  date: string
  presenters: { content: BlogAuthorType }[]
  image: StoryblokImageType
}

const WebinarHero = ({ title, date, presenters, image }: WebinarHeroProps) => {
  const headline = renderHeadline(title, "c-webinarHero__headline")

  const webinarPresenters = presenters?.map((presenter, i) => (
    <div
      key={`on-demand-webinar-${i}`}
      className="c-onDemandWebinarHero__presenter d-flex align-items-center"
    >
      <StoryblokImage
        image={presenter?.content?.profilePhoto}
        className="c-onDemandWebinarHero__presenter-photo"
      />

      <span className="c-onDemandWebinarHero__presenter-name">
        {presenter?.content?.name}
      </span>
    </div>
  ))

  return (
    <section className="c-webinarHero">
      <div className="container">
        <div className="row">
          <div className="col-xl-6 col-xxl-7 position-static">
            <div className="c-webinarHero__content">
              {headline}
              <p className="c-webinarHero__date">{date}</p>
              <div className="d-flex flex-column flex-sm-row flex-wrap">
                {webinarPresenters}
              </div>
              <StoryblokImage image={image} className="c-webinarHero__image" />
            </div>
          </div>
        </div>
      </div>

      <img
        src={heroPatternDark}
        className="c-webinarHero__patternDark"
        alt="simplity"
      />

      <img
        src={heroPatternOrange}
        className="c-webinarHero__patternOrange"
        alt="simplity"
      />
    </section>
  )
}

export default WebinarHero
