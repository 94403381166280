import React from "react"

import patternDark from "../../assets/images/hero-pattern-dark.svg"
import patternOrange from "../../assets/images/hero-pattern-orange.svg"

type ImageHorizontalSmallProps = {
  image: string
}

const ImageHorizontalSmall = ({ image }: ImageHorizontalSmallProps) => {
  return (
    <div className="c-imageHorizontalSmall">
      <img
        src={patternOrange}
        className="c-imageHorizontalSmall__patternOrange"
        alt=""
      />
      <img
        src={patternDark}
        className="c-imageHorizontalSmall__patternDark"
        alt=""
      />
      <div
        className="c-imageHorizontalSmall__image"
        style={{ backgroundImage: `url(${image})` }}
      />
    </div>
  )
}

export default ImageHorizontalSmall
