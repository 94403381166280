import React from "react"
import { render } from "storyblok-rich-text-react-renderer"

import { BenefitCardType } from "../../types"

type BenefitItemProps = {
  content: BenefitCardType
}

const BenefitItem = ({ content }: BenefitItemProps) => {
  return (
    <div className="c-benefitItem">
      <div
        className="c-benefitItem__imageWrapper"
        style={{ backgroundImage: `url(${content?.image.filename})` }}
      />

      <h4 className="c-benefitItem__title">{content?.title}</h4>

      <div className="c-benefitItem__list">{render(content?.text)}</div>
    </div>
  )
}

export default BenefitItem
