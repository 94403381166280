import React from "react"

import patternDark from "../../assets/images/hero-pattern-dark.svg"
import patternOrange from "../../assets/images/pattern-orange-small.svg"

import { CaseNumbersType } from "../../types"

type NumbersProps = {
  projectNumbers: CaseNumbersType
  show: boolean
}

const Numbers = ({ projectNumbers, show }: NumbersProps) => {
  const content = projectNumbers ? projectNumbers[0] : null

  const numbersBlocks = content?.numbersBlock?.map((block, i) => (
    <div
      className="c-numbers__block col-xl-3 col-sm-6"
      key={`number-block-${i}`}
    >
      <p className="c-numbers__number">{block?.number}</p>
      <p className="c-numbers__numberText">{block?.text}</p>
    </div>
  ))

  return show ? (
    <section className="c-numbers">
      <img src={patternOrange} className="c-numbers__patternOrange" alt="" />
      <img src={patternDark} className="c-numbers__patternDark" alt="" />

      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xxl-10">
            <div className="c-numbers__box">
              <div className="row mb-5">
                <div className="col-12 mb-3">
                  <h2 className="section-heading">{content?.title}</h2>
                </div>
              </div>

              <div className="row">
                <div className="col-xl-6 col-xxl-5">
                  <div className="c-numbers__text">{content?.text}</div>
                </div>
              </div>

              <div className="row">{numbersBlocks}</div>
            </div>
          </div>
        </div>
      </div>
    </section>
  ) : null
}

export default Numbers
