import React from "react"
import { render } from "storyblok-rich-text-react-renderer"

import { renderHeadline } from "../contentEdit"

import WhitepaperHeroForm, { WhitepaperFormProps } from "./WhitepaperHeroForm"

import patternOrange from "../../assets/images/hero-pattern-orange.svg"
import patternDark from "../../assets/images/hero-pattern-dark.svg"

import { StoryblokImageType } from "../../types"

type WhitepaperHeroType = {
  text: any
  title: any
  image: StoryblokImageType
  form: { content: WhitepaperFormProps }
  formSource: string
  docPath: string
  docName: string
}

const WhitepaperHero = ({
  title,
  text,
  image,
  form,
  docPath,
  docName,
  formSource,
}: WhitepaperHeroType) => {
  const headline = renderHeadline(title, "c-hero__headline")
  const renderedText = render(text)

  return (
    <section className="c-whitepaperHero">
      <div className="container">
        <div className="row justify-content-between">
          <div className="col-md-10 col-xl-6 col-xxl-5 position-static">
            <div className="c-whitepaperHero__content">
              <div className="row mb-2 mb-md-1 mb-lg-0">
                <div className="col-12">{headline}</div>
              </div>

              <div className="row">
                <div className="col-12">
                  <div className="c-hero__text">{renderedText}</div>
                </div>
              </div>

              <div
                className="c-whitepaperHero__image"
                style={{ backgroundImage: `url(${image.filename})` }}
              />
            </div>
          </div>

          <div className="d-none d-xl-block col-xl-6 col-xxl-5" id="form">
            <img
              src={patternOrange}
              className="c-whitepaperHero__patternOrange"
              alt="Simplity pattern"
            />

            <img
              src={patternDark}
              className="c-whitepaperHero__patternDark"
              alt="Simplity pattern"
            />

            <div className="c-whitepaperHero__form">
              <WhitepaperHeroForm
                {...form.content}
                docPath={docPath}
                docName={docName}
                formSource={formSource}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default WhitepaperHero
