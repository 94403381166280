import * as React from "react"
import CaseStudyItem from "../home/CaseStudyItem"
import BlogItem from "../home/BlogItem"
import WhitepaperItem from "../home/WhitepaperItem"
import WebinarItem from "../home/WebinarItem"
import NotFound from "../component-not-found"

const Components = type => {
  const ComponentList = {
    "Case Study": CaseStudyItem,
    Blog: BlogItem,
    Whitepaper: WhitepaperItem,
    Webinar: WebinarItem,
  }

  if (typeof ComponentList[type] === "undefined") {
    return NotFound
  }
  return ComponentList[type]
}

export default Components
