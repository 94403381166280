import React from "react"

import heroPatternDark from "../../assets/images/hero-pattern-dark.svg"
import heroPatternOrange from "../../assets/images/hero-pattern-orange.svg"

type HeroImageVertical = {
  image: string
}

const HeroHomeImage = ({ image }: HeroImageVertical) => {
  return (
    <div className="c-heroHomeImage">
      <img
        src={heroPatternDark}
        className="c-heroHomeImage__patternDark"
        alt=""
      />
      <img
        src={heroPatternOrange}
        className="c-heroHomeImage__patternOrange"
        alt=""
      />
      <div
        className="c-heroHomeImage__image"
        style={{ backgroundImage: `url(${image})` }}
      />
    </div>
  )
}

export default HeroHomeImage
