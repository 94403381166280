import React from "react"

import TeamTestimonial from "./TeamTestimonial"

import { MemberType } from "../../types"

type TeamProps = {
  title: string
  text: string
  members: MemberType[]
}

const Team = ({ title, text, members }: TeamProps) => {
  return (
    <section className="c-team">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xxl-10">
            <div className="c-clients__box">
              <div className="row mb-5">
                <div className="col-12 mb-2 mb-sm-3">
                  <h2 className="section-heading">{title}</h2>
                </div>
              </div>

              <div className="row pt-1">
                <div className="col-lg-10 col-xl-7 col-xxl-5">
                  <h3 className="section-subheading">{text}</h3>
                </div>
              </div>

              <div className="row">
                <div className="col-12">
                  <TeamTestimonial members={members} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Team
