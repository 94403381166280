import React from "react"

import { HeaderType, SeoMetaType, StoryblokDocumentType, StoryblokImageType } from "../types"
import Layout from "../components/layout/Layout"
import Hero from "../components/shared/Hero"
import SimplityEuropeFiftyFiftyBlock from "../components/simplityEurope/SimplityEuropeFiftyFiftyBlock"
import FinancialStatements from "../components/simplityEurope/FinancialStatements"

export type SimplityEuropeProps = {
  seoMeta: SeoMetaType;
  header: HeaderType[];
  pathname: string;
  contactTitle: string;
  companyName: string;
  contactText: string;
  contactImage: StoryblokImageType;
  documentsTitle: string;
  documentLabel: string;
  documentDownloadLabel: string;
  documents: StoryblokDocumentType[];
}

const SimplityEuropeHomepage = ({
                          seoMeta,
                          header,
                          pathname,
                          contactTitle,
                          companyName,
                          contactText,
                          contactImage,
                          documentsTitle,
                          documentLabel,
                          documentDownloadLabel,
                          documents
                        }: SimplityEuropeProps) => {
  return (
    <Layout
      contentClass="SimplityEuropeHomepage"
      seoMeta={seoMeta}
      pathname={pathname}
      hideNavigation={true}
    >
      <Hero header={header} />
      <section className="c-simplityEuropeContact">
        <SimplityEuropeFiftyFiftyBlock
          contactImage={contactImage}
          contactTitle={contactTitle}
          companyName={companyName}
          contactText={contactText}
        />
      </section>
      <FinancialStatements
        documentsTitle={documentsTitle}
        documentLabel={documentLabel}
        documentDownloadLabel={documentDownloadLabel}
        documents={documents}
      />
    </Layout>
  )
}

export default SimplityEuropeHomepage
