import React from "react"

import Layout from "../components/layout/Layout"
import Hero from "../components/shared/Hero"

import { FiftyFiftyCardEuProjectsType, HeaderType, SeoMetaType } from "../types"
import ProjectFiftyFiftyBlock from "../components/euCofundedProjects/ProjectFiftyFiftyBlock"

type SolutionsOverviewProps = {
  seoMeta: SeoMetaType;
  header: HeaderType[];
  pathname: string;
  projects: FiftyFiftyCardEuProjectsType[];
}

const EuCoFundedProjects = ({
                              seoMeta,
                              header,
                              pathname,
                              projects
                            }: SolutionsOverviewProps) => {
  return (
    <Layout
      contentClass="EuCofundedProjectsPage"
      seoMeta={seoMeta}
      pathname={pathname}
    >
      <Hero header={header} />
      <section>
        {projects?.map((project, i) => (
          <ProjectFiftyFiftyBlock
            key={`project-${i}`}
            content={project}
            rightAlign={!(i % 2)}
          />
        ))}
      </section>
    </Layout>
  )
}

export default EuCoFundedProjects
