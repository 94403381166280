import React from "react"

import FiftyFiftyBlock from "../shared/FiftyFiftyBlock"

import { FiftyFiftyCardType } from "../../types"

type IconityProps = {
  content: FiftyFiftyCardType[]
}

const Iconity = ({ content }: IconityProps) => {
  return (
    <section className="c-iconity" id="careers">
      <FiftyFiftyBlock content={content[0]} rightAlign={true} isLast={true} />
    </section>
  )
}

export default Iconity
