import React, { useState } from "react"
import Accordion from "react-bootstrap/Accordion"
import classNames from "classnames"
import MobileDropdownComponents from "../componentKeys/dropDownMobileComponents"

import ArrowIcon from "../../assets/images/icons/dropdown.svg"

import { SubmenuItem } from "../../types"

type MobileSubMenuProps = {
  items: SubmenuItem[]
  label: string
  groupSelected: boolean
}

const MobileSubMenu = ({ items, label, groupSelected }: MobileSubMenuProps) => {
  const [menuOpened, setMenu] = useState(null)

  const renderClassNames = () =>
    classNames("c-mobileSubMenu", "", {
      "c-mobileSubMenu--opened": !!menuOpened,
    })

  const renderLabelClassNames = () =>
    classNames("hover-bcgUnderline", {
      "c-mobileSubMenu__label--active": groupSelected,
    })

  const submenuItems = items?.map((item, i) =>
    React.createElement(MobileDropdownComponents(item.component), {
      key: i,
      ...item,
    })
  )

  return (
    <Accordion
      className={renderClassNames()}
      activeKey={menuOpened}
      onSelect={e => setMenu(e)}
    >
      <Accordion.Toggle eventKey="0" className="c-mobileSubMenu__toggler">
        <span className={renderLabelClassNames()}>
          {label}
          <ArrowIcon />
        </span>
      </Accordion.Toggle>

      <Accordion.Collapse eventKey="0">
        <ul className="c-mobileSubMenu__content d-flex flex-column align-items-center">
          {submenuItems}
        </ul>
      </Accordion.Collapse>
    </Accordion>
  )
}

export default MobileSubMenu
