import React from "react"

import Layout from "../components/layout/Layout"
import StickyForm from "../components/upcomingWebinar/StickyForm"
import WebinarHero from "../components/upcomingWebinar/WebinarHero"
import WebinarInfo from "../components/upcomingWebinar/WebinarInfo"
import WebinarMobileForm from "../components/upcomingWebinar/WebinarMobileForm"

import { SeoMetaType, BlogAuthorType, StoryblokImageType } from "../types"

import { WhitepaperFormProps } from "../components/whitepapers/WhitepaperHeroForm"

type UpcomingWebinarProps = {
  seoMeta: SeoMetaType
  pathname: string
  webinarTitle: string
  date: string
  presenters: { content: BlogAuthorType }[]
  image: StoryblokImageType
  infoTitle: string
  infoText: any
  form: { content: WhitepaperFormProps }
  formSource: string
}

const UpcomingWebinar = ({
  seoMeta,
  pathname,
  webinarTitle,
  date,
  presenters,
  image,
  infoTitle,
  infoText,
  form,
  formSource,
}: UpcomingWebinarProps) => {
  return (
    <Layout
      contentClass="upcomingWebinarPage"
      seoMeta={seoMeta}
      pathname={pathname}
    >
      <StickyForm form={form} formSource={formSource} />
      <div className="upcomingWebinarPage__sectionWrapper">
        <WebinarHero
          title={webinarTitle}
          date={date}
          presenters={presenters}
          image={image}
        />
        <WebinarInfo title={infoTitle} text={infoText} />
        <WebinarMobileForm form={form} formSource={formSource} />
      </div>
    </Layout>
  )
}

export default UpcomingWebinar
