import React, { useState } from "react"
import { render } from "storyblok-rich-text-react-renderer"
import classNames from "classnames"

import StoryblokImage from "../shared/StoryblokImage"
import AutoHeight from "../shared/AutoHeight"

import { ServiceCardType } from "../../types"

type ServiceAccordionProps = {
  i: number
  card: ServiceCardType
  identifierType: string
}

const ServiceAccordion = ({
  card,
  i,
  identifierType,
}: ServiceAccordionProps) => {
  const [isExpanded, setExpanded] = useState(false)

  const renderClassNames = () =>
    classNames("c-serviceAccordion", {
      "c-serviceAccordion--opened": isExpanded,
    })

  return (
    <div className={renderClassNames()}>
      <AutoHeight identifier={`${identifierType}-${i}`}>
        <div
          className="c-serviceAccordion__content"
          onClick={() => setExpanded(!isExpanded)}
        >
          <div className="c-serviceCard__iconWrapper">
            <StoryblokImage image={card?.content?.icon} />
          </div>

          <h4 className="c-serviceCard__title">{card?.content?.title}</h4>

          {!isExpanded ? (
            <div className="c-serviceCard__text c-serviceCard__text--shortText">
              {card?.content?.shortText}
            </div>
          ) : (
            <div className="c-serviceCard__text">
              {render(card?.content?.longText)}
            </div>
          )}

          {card?.content?.longText && (
            <div className="c-serviceCard__itemLinkWrapper d-flex align-items-end flex-grow-1">
              <span className="c-serviceCard__itemLink hover-bcgUnderline">
                {!isExpanded
                  ? card?.content?.ctaButton1
                  : card?.content?.ctaButton2}
              </span>
            </div>
          )}
        </div>
      </AutoHeight>
    </div>
  )
}

export default ServiceAccordion
