import React from "react"
import classNames from "classnames"
import { render } from "storyblok-rich-text-react-renderer"

import StoryblokLink from "../shared/StoryblokLink"

import DropdownComponents from "../componentKeys/dropDownComponents"

import { SubmenuItem, PromoCard } from "../../types"

type NavDropdownProps = {
  isExpanded: boolean
  items: SubmenuItem[]
  promotedItem: PromoCard[]
  ctaLabel: string
  button?: SubmenuItem[]
  pathname: string
}

const NavDropdown = ({
  isExpanded,
  items,
  promotedItem,
  ctaLabel,
  button,
  pathname,
}: NavDropdownProps) => {
  const linkObject = {
    cached_url: promotedItem ? promotedItem[0].full_slug : null,
    linktype: "story",
  }
  const promotedItemHeadline = promotedItem
    ? render(promotedItem[0].content.title)
    : null

  const renderClassNames = () =>
    classNames("header__dropdown", {
      "d-flex": isExpanded,
      "flex-column": !promotedItem?.length,
      "header__dropdown--expanded": isExpanded,
      "header__dropdown--promoted": promotedItem?.length,
    })

  const menuItems = items?.map((item, i) =>
    React.createElement(DropdownComponents(item.component), {
      key: i,
      ...item,
      pathname: pathname,
    })
  )

  const menuButton = button.length ? (
    <li className="header__dropdown-item">
      <StoryblokLink
        to={button[0].link}
        className="btn-tertiary hover-bcgUnderline"
      >
        {button[0].label}
      </StoryblokLink>
    </li>
  ) : null

  return (
    <div className={renderClassNames()}>
      {!promotedItem?.length ? (
        <ul className="header__dropdown-content">
          {menuItems}
          {menuButton}
        </ul>
      ) : (
        <>
          <ul className="header__dropdown-content">
            {menuItems}
            {menuButton}
          </ul>

          <div className="header__promotion">
            <div
              className="header__promotion-image"
              style={{
                backgroundImage: `url(${promotedItem[0].content.image.filename})`,
              }}
            ></div>

            <div className="header__promotion-text">
              <div className="header__promotion-name">
                {promotedItemHeadline}
              </div>
              <StoryblokLink
                to={linkObject}
                className="btn-tertiary hover-bcgUnderline"
              >
                {ctaLabel}
              </StoryblokLink>
            </div>
          </div>
        </>
      )}
    </div>
  )
}

export default NavDropdown
