import React from "react"

import ServiceItem from "./ServiceCard"
import ServiceAccordion from "./ServiceAccordion"

import { cutInLines } from "../contentEdit"

import patternDark from "../../assets/images/pattern-dark-big.svg"

import { ServiceCardType, StoryblokImageType } from "../../types"
import { useExpandableCard } from "./useExpandableCardHook"

type DataAssessmentProps = {
  title: string
  text: string
  cards: ServiceCardType[]
  image: StoryblokImageType
}

const DataAssessment = ({ title, text, cards, image }: DataAssessmentProps) => {
  const {
    cardsContainer,
    closeCard,
    expandedDirection,
    isCardExpanded,
    openCard,
  } = useExpandableCard()

  const cardItems = cards?.map((card, i) => (
    <div key={`service-item-${i}`} className="col-sm-6">
      <ServiceItem
        card={card}
        isExpanded={isCardExpanded(i)}
        onClick={elm => openCard(i, elm)}
        onClose={closeCard}
        expandDirection={expandedDirection}
      />
    </div>
  ))

  const mobileItems = cards?.map((card, i) => {
    return (
      <div className="col-12" key={`service-accordion-${i}`}>
        <ServiceAccordion card={card} i={i} identifierType="assessment" />
      </div>
    )
  })

  return (
    <section className="c-dataAssessment" id="data-assessment">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xxl-10">
            <div className="row">
              <div className="col-lg-10 col-xl-8 col-xxl-6">
                <div className="c-dataAssessment__box">
                  <h2 className="c-dataAssessment__headline">
                    {cutInLines(title)}
                  </h2>

                  <p className="c-dataAssessment__text">{text}</p>

                  <div className="c-dataAssessment__cards">
                    <div className="row d-none d-sm-flex" ref={cardsContainer}>
                      {cardItems}
                    </div>

                    <div className="row d-sm-none">{mobileItems}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <img src={patternDark} className="c-dataAssessment__pattern" alt="" />
      <div
        className="c-dataAssessment__image"
        style={{ backgroundImage: `url(${image?.filename})` }}
      />
    </section>
  )
}

export default DataAssessment
