import React from "react"

import StoryblokImage from "../shared/StoryblokImage"

import { ClientsLogos } from "../../types"

type ClientsProps = {
  title: string
  text: string
  logos: ClientsLogos[]
}

const Clients = ({ title, text, logos }: ClientsProps) => {
  const clientLogos = logos
    ?.sort((a, b) => a.position - b.position)
    .map((logo, i) => (
      <li
        key={`client-logo-${i}`}
        className="c-clients__logoItem d-flex justify-content-center align-items-center"
      >
        <StoryblokImage
          image={logo?.content?.logo}
          className="c-clients__logo"
        />
      </li>
    ))

  return (
    <section className="c-clients">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xxl-10">
            <div className="c-clients__box">
              <div className="row mb-5">
                <div className="col-12 mb-2 mb-sm-3">
                  <h2 className="section-heading">{title}</h2>
                </div>
              </div>

              <div className="row pt-1">
                <div className="col-lg-10 col-xl-7 col-xxl-5">
                  <h3 className="section-subheading">{text}</h3>
                </div>
              </div>

              <div className="row">
                <div className="col-12">
                  <ul className="c-clients__content d-flex align-items-center justify-content-center flex-wrap">
                    {clientLogos}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Clients
