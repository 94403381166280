import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import ClassNames from "classnames"

import FooterTop from "./FooterTop"
import FooterColumns from "./FooterColumns"
import FooterSocials from "./FooterSocials"
import FooterBottom from "./FooterBottom"

import pattern from "../../assets/images/footer-pattern-orange.svg"

type FooterProps = {
  errorPage?: boolean
  hideNavigation?: boolean
}

const useFooterDataFromStoryblok = () => {
  const queryResult = useStaticQuery(
    graphql`
      query {
        storyblokEntry(field_component: { eq: "footer" }) {
          content
        }
      }
    `
  )

  return queryResult.storyblokEntry
    ? JSON.parse(queryResult.storyblokEntry.content)
    : undefined
}

const Footer = ({ errorPage, hideNavigation }: FooterProps) => {
  const content = useFooterDataFromStoryblok()

  const renderClasses = () =>
    ClassNames("footer", {
      "footer--404": errorPage
    })

  return (
    <footer className={renderClasses()}>
      {!errorPage && <img src={pattern} className="footer__pattern" alt="" />}

      <div className="container">
        <FooterTop
          logo={content?.logo}
          slogan={content?.slogan}
          newsletterEnabled={!hideNavigation && (content?.newsletterEnabled || true)}
          newsletterHeadline={content?.newsletterHeadline}
          newsletterPlaceholder={content?.newsletterPlaceholder}
        />
        {!hideNavigation && (
          <>
            <FooterColumns boxes={content?.linkBoxes} />
            <FooterSocials
              facebook={content?.facebookLink}
              linkedin={content?.linkedinLink}
              twitter={content?.twitterLink}
            />
          </>
        )}
        <FooterBottom
          copyright={content?.copyright}
          bottomLinks={!hideNavigation ? content?.bottomLinks : []}
        />
      </div>
    </footer>
  )
}

export default Footer
