import React from "react"
import { FiftyFiftyCardEuProjectsType } from "../../types"
import FiftyFiftyBlockBase from "../shared/FiftyFiftyBlockBase"
import euBadge from '../../assets/images/euBadge.jpg'

type ProjectFiftyFiftyBlockProps = {
  content: FiftyFiftyCardEuProjectsType;
  rightAlign?: boolean;
}

const ProjectFiftyFiftyBlock = ({ content, rightAlign }: ProjectFiftyFiftyBlockProps) => {
  return (
    <div className="c-projectFiftyFiftyBlock">
      <FiftyFiftyBlockBase
        backgroundImageUrl={content?.image.filename}
        rightAlign={rightAlign}
        portrait={rightAlign}
      >
        <div>
          <h2 className="c-projectFiftyFiftyBlock__headline">{content?.title}</h2>
          <p className="c-projectFiftyFiftyBlock__text">{content?.text}</p>
          <img src={euBadge} className="c-projectFiftyFiftyBlock__euBadge" alt="" />
          <p className="c-projectFiftyFiftyBlock__text">
            {content?.projectIdentifier.split("\n").map(line => (
              <>{line}<br /></>
            ))}
          </p>
        </div>
      </FiftyFiftyBlockBase>
    </div>
  )
}

export default ProjectFiftyFiftyBlock
