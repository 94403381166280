import React from "react"

import heroPatternDark from "../../assets/images/hero-pattern-dark.svg"
import patternOrange from "../../assets/images/footer-pattern-orange.svg"
import heroPatternOrange from "../../assets/images/hero-pattern-orange.svg"

type HeroImageVerticalBigProps = {
  image: string
}

const HeroImageVerticalBig = ({ image }: HeroImageVerticalBigProps) => {
  return (
    <div className="c-heroImageVerticalBig">
      <img
        src={heroPatternDark}
        className="c-heroImageVerticalBig__patternDarkLeft"
        alt=""
      />
      <img
        src={heroPatternDark}
        className="c-heroImageVerticalBig__patternDark"
        alt=""
      />
      <img
        src={patternOrange}
        className="c-heroImageVerticalBig__patternOrange"
        alt=""
      />
      <img
        src={heroPatternOrange}
        className="c-heroImageVerticalBig__patternOrangeBig d-xl-none"
        alt=""
      />
      <div
        className="c-heroImageVerticalBig__image"
        style={{ backgroundImage: `url(${image})` }}
      />
    </div>
  )
}

export default HeroImageVerticalBig
