import React from "react"

import patternDark from "../../assets/images/hero-pattern-dark.svg"
import patternOrange from "../../assets/images/footer-pattern-orange.svg"

type ImageHorizontalMediumProps = {
  image: string
}

const ImageHorizontalMedium = ({ image }: ImageHorizontalMediumProps) => {
  return (
    <div className="c-imageHorizontalMedium">
      <img
        src={patternDark}
        className="c-imageHorizontalMedium__patternDark"
        alt=""
      />
      <img
        src={patternOrange}
        className="c-imageHorizontalMedium__patternOrange"
        alt=""
      />
      <div
        className="c-imageHorizontalMedium__image"
        style={{ backgroundImage: `url(${image})` }}
      />
    </div>
  )
}

export default ImageHorizontalMedium
