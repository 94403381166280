import React from "react"

import quotes from "../../assets/images/quotes.svg"

type HighlightTextProps = {
  text: string
  show: boolean
}

const HighlightText = ({ text, show }: HighlightTextProps) => {
  return show ? (
    <section className="c-highlightText">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xxl-10">
            <div className="c-highlightText__box">
              <div className="row justify-content-center">
                <div className="col-xl-10 col-xxl-8">
                  <div className="c-highlightText__content">
                    <img
                      src={quotes}
                      className="c-highlightText__quotes"
                      alt=""
                    />
                    {text}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  ) : null
}

export default HighlightText
