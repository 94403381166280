import React from "react"
import { SubmenuItem } from "../../types"

import StoryblokLink from "../shared/StoryblokLink"

type NavDropdownMobileSmallItemsProps = {
  links: SubmenuItem[]
}

const NavDropdownMobileSmallItems = ({
  links,
}: NavDropdownMobileSmallItemsProps) => {
  const smallItems = links?.map((item, i) => (
    <li
      key={`mobileSmallItems-${i}`}
      className="c-mobileSubMenu__content-item c-mobileSubMenu__content-item--small"
    >
      <StoryblokLink to={item.link} className="hover-bcgUnderline">
        {item.label}
      </StoryblokLink>
    </li>
  ))

  return <>{smallItems}</>
}

export default NavDropdownMobileSmallItems
