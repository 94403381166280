import * as React from "react"

import NotFound from "../component-not-found"
import Vertical from "../shared/HeroImageVertical"
import VerticalBig from "../shared/HeroImageVerticalBig"
import Horizontal from "../shared/ImageHorizontal"
import HorizontalSmall from "../shared/ImageHorizontalSmall"
import HorizontalMedium from "../shared/ImageHorizontalMedium"
import HorizontalStudy from "../shared/ImageHorizontalStudy"
import HorizontalLarge from "../shared/ImageHorizontalLarge"

const Components = type => {
  const ComponentList = {
    "663x990": Vertical,
    "805x994": VerticalBig,
    "944x620": Horizontal,
    "944x620CaseStudy": HorizontalStudy,
    "802x620": HorizontalSmall,
    "941x705": HorizontalMedium,
    "1083x620": HorizontalLarge,
  }

  if (typeof ComponentList[type] === "undefined") {
    return NotFound
  }
  return ComponentList[type]
}

export default Components
