import React from "react"

import StoryblokLink from "../shared/StoryblokLink"

import { renderCardHeadline } from "../contentEdit"

import { ThreeCardBlog } from "../../types"

type ThreeBlockBlogProps = {
  full_slug: string
  content: ThreeCardBlog
  ctaLabel?: string
}

const ThreeBlockBlog = ({ full_slug, content, ctaLabel = 'Read more' }: ThreeBlockBlogProps) => {
  const linkObject = {
    cached_url: full_slug,
    linktype: "story",
  }

  const cardHeadline = renderCardHeadline(
    content?.blogTitle,
    "c-threeBlock__heading"
  )

  return (
    <StoryblokLink
      to={linkObject}
      className="c-threeBlock__item d-flex flex-column"
    >
      <div className="c-threeBlock__hoverWrapper">
        <div
          className="c-threeBlock__imageWrapper"
          style={{
            backgroundImage: `url(${content?.headerImageThumb?.filename})`,
          }}
        />

        <div className="c-threeBlock__content">
          <span className="c-threeBlock__type">{content?.component}</span>

          {cardHeadline}
          <span className="btn-tertiary hover-bcgUnderline">{ctaLabel}</span>
        </div>
      </div>
    </StoryblokLink>
  )
}

export default ThreeBlockBlog
