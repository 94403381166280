import React from "react"

import WebinarForm from "./WebinarForm"

import { WebinarFormProps } from "./WebinarForm"

type StickyFormProps = {
  form: { content: WebinarFormProps }
  formSource: string
}

const StickyForm = ({ form, formSource }: StickyFormProps) => {
  return (
    <section className="c-stickyForm">
      <div className="container h-100">
        <div className="row justify-content-end h-100">
          <div className="col-lg-6 col-xxl-4">
            <div className="c-stickyForm__content">
              <WebinarForm {...form?.content} formSource={formSource} />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default StickyForm
