import React from 'react';
import { StoryblokDocumentType } from "../../types"

type FinancialStatementCardProps = {
  label: string;
  downloadLabel: string;
  document: StoryblokDocumentType;
}

const FinancialStatementCard = ({ label, downloadLabel, document }: FinancialStatementCardProps) => {
  return (
    <a
      className="c-financialStatementCard"
      href={document.filename}
      target="_blank"
      rel="noopener noreferrer"
    >
      <div className="c-financialStatementCard__hoverWrapper">
        <div className="c-financialStatementCard__content">
          <span className="c-financialStatementCard__label">{label}</span>
          <h2 className="c-financialStatementCard__name">{document.name}</h2>
          <span className="btn-tertiary hover-bcgUnderline">{downloadLabel}</span>
        </div>
      </div>
    </a>
  )
}

export default FinancialStatementCard
