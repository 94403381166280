import React, { SyntheticEvent, useState } from "react"

export type TextAreaProps = {
  name: string
  label?: string
  value?: string
  setValue: (name: string, value: string) => void
  error?: string
  clearError?: (name: string) => void
  className?: string
}

const TextArea = ({
  name,
  label,
  value = "",
  setValue,
  error,
  clearError,
  className,
}: TextAreaProps) => {
  const [isFocused, setFocused] = useState(false)
  const hasError = !!error

  const handleChange = (e: SyntheticEvent<HTMLTextAreaElement>) => {
    if (error && clearError) {
      clearError(name)
    }
    setValue(name, e.currentTarget.value)
  }

  return (
    <div
      className={`textArea ${isFocused ? "textArea--focused" : ""} ${
        hasError && !isFocused ? "textArea--error" : ""
      } ${className}`}
    >
      <label htmlFor={name} className={"textArea__label"}>
        {label}
      </label>
      <textarea
        id={name}
        name={name}
        value={value}
        onChange={handleChange}
        onFocus={() => setFocused(true)}
        onBlur={() => setFocused(false)}
        className="textArea__input"
      />
      {hasError ? <span className={"textArea__error"}>{error}</span> : null}
    </div>
  )
}

export default TextArea
