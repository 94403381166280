import React from "react"
import Navbar from "react-bootstrap/Navbar"
import { Link } from "gatsby"

import MobileSubMenu from "./MobileSubMenu"
import StoryblokLink from "../shared/StoryblokLink"

import { MenuItemType, ctaTarget } from "../../types"

type MobileMenuProps = {
  isMenuVisible: boolean
  items: MenuItemType[]
  ctaButton: string
  ctaLink: ctaTarget
  pathname: string
}

const MobileMenu = ({
  isMenuVisible,
  items,
  ctaButton,
  ctaLink,
  pathname = "",
}: MobileMenuProps) => {
  const menuItems = items?.map((item, i) => {
    const groupSelected = pathname.includes(item?.link.cached_url)

    const menuItem = item.submenuItems.length ? (
      <li
        key={`mobileMenuItem-${i}`}
        className="header__mobileMenu-item header__mobileMenu-item--accordion"
      >
        <MobileSubMenu
          items={item.submenuItems}
          label={item.label}
          groupSelected={groupSelected}
        />
      </li>
    ) : (
      <li
        key={`mobileMenuItem-${i}`}
        className="header__mobileMenu-item d-flex flex-column align-items-center"
      >
        <StoryblokLink to={item.link} className="hover-bcgUnderline">
          {item.label}
        </StoryblokLink>
      </li>
    )

    return menuItem
  })

  return (
    <Navbar
      expanded={isMenuVisible}
      expand="xl"
      className="header__mobileNavbar d-xl-none p-0"
    >
      <Navbar.Collapse>
        <ul className="header__mobileMenu d-flex flex-column">
          {menuItems}
          {ctaButton && ctaLink.cached_url && (
            <li className="header__mobileMenu-item d-flex flex-column align-items-center">
              <StoryblokLink to={ctaLink} className="btn-primary">
                {ctaButton}
              </StoryblokLink>
            </li>
          )}
        </ul>
      </Navbar.Collapse>
    </Navbar>
  )
}

export default MobileMenu
