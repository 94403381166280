import React from "react"
import classNames from "classnames"
import { render, NODE_IMAGE } from "storyblok-rich-text-react-renderer"
import heroPatternDark from "../../assets/images/hero-pattern-dark.svg"

type BlogPostTextProps = {
  leadParagraph: string
  blogBody: string
}

const BlogPostText = ({ leadParagraph, blogBody }: BlogPostTextProps) => {
  return (
    <section className={classNames("c-blogPostText")}>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="c-blogPostContent">
              <div className="c-blogPostText__leadParagraph">
                {leadParagraph}
              </div>
              {render(blogBody, {
                nodeResolvers: {
                  [NODE_IMAGE]: (children, props) => (
                    <img className="c-blogPostText__image" {...props} />
                  ),
                },
              })}
              <img
                src={heroPatternDark}
                className="c-blogPostText__rightPatternDark"
              />
              <img
                src={heroPatternDark}
                className="c-blogPostText__leftPatternDark"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
export default BlogPostText
