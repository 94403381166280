import React, { useEffect, useRef } from "react"
import Slider from "react-slick"

import StoryblokImage from "../shared/StoryblokImage"

import { TestimonialItem } from "../../types"

type ProjectEstimateSliderProps = {
  activeSlide: number
  slides: TestimonialItem[]
}

const ProjectEstimateSlider = ({
  activeSlide,
  slides,
}: ProjectEstimateSliderProps) => {
  const slider = useRef(null)

  useEffect(() => {
    slider.current?.slickGoTo(activeSlide)
  }, [activeSlide])

  const settings = {
    infinite: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    dots: false,
    draggable: false,
  }

  const items = slides
    ?.filter(slide => !!slide.content)
    .map((slide, i) => (
      <div key={`testimonials-${i}`} className="c-projectEstimateSlider__item">
        <div className="c-projectEstimateSlider__item-text">
          {slide?.content.testimonialText}
        </div>

        <StoryblokImage
          image={slide?.content.profilePhoto}
          className="c-projectEstimateSlider__image"
        />

        <p className="c-projectEstimateSlider__author-text">
          <strong>
            {slide?.content.name}
            <span className="d-none d-lg-inline-block">,</span>
          </strong>
          &nbsp;
          <span className="c-projectEstimateSlider__author-position">
            {slide?.content.position}
          </span>
        </p>
        <StoryblokImage
          image={slide?.content.companyLogo}
          className="c-projectEstimateSlider__logo"
        />
      </div>
    ))

  return (
    <div className="c-projectEstimateSlider">
      <Slider ref={slider} {...settings}>
        {items}
      </Slider>
    </div>
  )
}

export default ProjectEstimateSlider
