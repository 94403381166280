import React from "react"

import WebinarForm from "./WebinarForm"

import patternOrange from "../../assets/images/pattern-orange-small.svg"
import patternDark from "../../assets/images/hero-pattern-dark.svg"

import { WebinarFormProps } from "./WebinarForm"

type WebinarMobileFormProps = {
  form: { content: WebinarFormProps }
  formSource: string
}

const WebinarMobileForm = ({ form, formSource }: WebinarMobileFormProps) => {
  return (
    <section className="c-webinarMobileForm">
      <img
        src={patternOrange}
        className="c-webinarMobileForm__patternOrange"
        alt=""
      />
      <img
        src={patternDark}
        className="c-webinarMobileForm__patternDark"
        alt=""
      />

      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="c-webinarMobileForm__content">
              <WebinarForm {...form?.content} formSource={formSource} />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default WebinarMobileForm
