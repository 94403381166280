import React from "react"

import { renderHeadline } from "../contentEdit"

import patternOrange from "../../assets/images/footer-pattern-orange.svg"
import patternDark from "../../assets/images/hero-pattern-dark.svg"

import { StoryblokImageType } from "../../types"
import GetInTouchForm, { GetInTouchFormProps } from "./GetInTouchForm"

type FormHeroProps = {
  title: any
  text: string
  image: StoryblokImageType
  form: { content: GetInTouchFormProps }
}

const FormHero = ({ title, text, image, form }: FormHeroProps) => {
  const headline = renderHeadline(title, "c-hero__headline")

  return (
    <section className="c-formHero">
      <img src={patternDark} className="c-formHero__patternDark" alt="" />

      <div className="container">
        <div className="row justify-content-between">
          <div className="col-xl-6 col-xxl-7">
            <div className="c-formHero__content">
              <div className="row mb-2 mb-md-1 mb-lg-0">
                <div className="col-12">{headline}</div>
              </div>

              <div className="row">
                <div className="col-xl-10 col-xxl-9">
                  <p className="c-hero__text">{text}</p>
                </div>
              </div>

              <div
                className="c-formHero__imageBox"
                style={{ backgroundImage: `url(${image.filename})` }}
              >
                <img
                  src={patternOrange}
                  alt=""
                  className="c-formHero__patternOrange"
                />
              </div>
            </div>
          </div>

          <div className="col-xl-6 col-xxl-5 col-xxxl-4">
            <img
              src={patternOrange}
              alt=""
              className="c-formHero__form-patternOrange"
            />
            <img
              src={patternDark}
              alt=""
              className="c-formHero__form-patternDark"
            />
            <div className="c-formHero__form">
              <GetInTouchForm {...form.content} />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default FormHero
