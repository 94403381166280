import React from "react"
import { useZohoFormLogic, ZohoFormFields } from "../zohoForms/zohoLogic"
import { CommonFormProps } from "../../types"
import TextField from "../zohoForms/TextField"
import CheckBox from "../zohoForms/CheckBox"
import fetchingGif from "../../assets/images/fetching.gif"

export const ZOHO_WHITEPAPER_SUBMIT_URL =
  "https://form.simplity.ai/simplitygroup/form/SimplityWhitepaperForm/formperma/-g-w8gPwUH6p_BWv_nAVtlz3qPQaIZcn1O6O2LYxp5M/htmlRecords/submit"

export type WhitepaperFormFields = {
  firstName: string
  lastName: string
  company: string
  email: string
  marketing: boolean
}

const zohoWhitepaperFormFields: ZohoFormFields<WhitepaperFormFields> = {
  firstName: {
    zohoFieldName: "SingleLine7",
    initialValue: "",
    required: true,
  },
  lastName: {
    zohoFieldName: "SingleLine8",
    initialValue: "",
    required: true,
  },
  company: {
    zohoFieldName: "SingleLine9",
    initialValue: "",
    required: true,
  },
  email: {
    zohoFieldName: "Email",
    initialValue: "",
    required: true,
    runEmailValidation: true,
  },
  marketing: {
    zohoFieldName: "DecisionBox",
    initialValue: false,
    required: false,
  },
}

export type WhitepaperFormProps = {
  docPath: string
  docName: string
  formSource: string
} & CommonFormProps

const WhitepaperHeroForm = ({
  titleForm,
  firstNameLabel,
  lastNameLabel,
  companyLabel,
  emailLabel,
  marketingLabel,
  submitLabel,
  formSource,
  successRedirectUrl,
  requiredFieldErrorMessage,
  validEmailErrorMessage,
  submitErrorMessage,
  docPath,
  docName,
}: WhitepaperFormProps) => {
  const {
    values,
    errors,
    isFetching,
    submitError,
    setValue,
    clearError,
    doSubmit,
  } = useZohoFormLogic<WhitepaperFormFields>({
    formIdentifier: formSource,
    zohoSubmitUrl: ZOHO_WHITEPAPER_SUBMIT_URL,
    successRedirectUrl: successRedirectUrl,
    requiredFieldValidationMessage: requiredFieldErrorMessage,
    validEmailValidationMessage: validEmailErrorMessage,
    submitErrorMessage: submitErrorMessage,
    zohoFormFields: zohoWhitepaperFormFields,
  })

  return (
    <div className="form__container">
      <h3 className="form__headline">{titleForm}</h3>
      <div className="form__row">
        <TextField
          name="firstName"
          label={firstNameLabel}
          value={values.firstName}
          setValue={setValue}
          error={errors.firstName}
          clearError={clearError}
        />
        <TextField
          name="lastName"
          label={lastNameLabel}
          value={values.lastName}
          setValue={setValue}
          error={errors.lastName}
          clearError={clearError}
        />
      </div>
      <TextField
        name="company"
        label={companyLabel}
        value={values.company}
        setValue={setValue}
        error={errors.company}
        clearError={clearError}
      />
      <TextField
        name="email"
        label={emailLabel}
        value={values.email}
        setValue={setValue}
        error={errors.email}
        clearError={clearError}
      />
      <div className="form__checkBoxContainer">
        <CheckBox
          name="marketing"
          label={marketingLabel}
          value={values.marketing}
          setValue={setValue}
        />
      </div>
      <div className="form__buttonContainer">
        {isFetching ? (
          <div className="form__fetchingBox">
            <img src={fetchingGif} />
          </div>
        ) : (
          <button
            onClick={() => doSubmit(docPath, docName)}
            className="form__submit"
          >
            {submitLabel}
          </button>
        )}
        {!!submitError && <p className="form__submit-error">{submitError}</p>}
      </div>
    </div>
  )
}

export default WhitepaperHeroForm
