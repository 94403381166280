import React from "react"

import StoryblokLink from "../shared/StoryblokLink"

import { FooterBoxItem } from "../../types"

type FooterColumnsProps = {
  boxes: FooterBoxItem[]
}

const FooterColumns = ({ boxes }: FooterColumnsProps) => {
  const footerLists = boxes?.map((column, i) => (
    <div key={`footer-column-${i}`} className="col-sm-6 col-lg-4 col-xl-3">
      <div className="footer__list">
        <div className="footer__list-topWrapper">
          <StoryblokLink
            to={column?.headerLink}
            className="footer__list-top hover-bcgUnderline"
          >
            {column.headerLabel}
          </StoryblokLink>
        </div>

        <ul className="footer__listGroup">
          {column?.links?.map((linkItem, i) => (
            <li key={`column-link-${i}`} className="footer__listGroup-item">
              <StoryblokLink to={linkItem?.link} className="hover-bcgUnderline">
                {linkItem.label}
              </StoryblokLink>
            </li>
          ))}
        </ul>
      </div>
    </div>
  ))

  return <div className="row footer__columns">{footerLists}</div>
}

export default FooterColumns
