import React, { SyntheticEvent, useState } from "react"

export type TextFieldProps = {
  name: string
  label?: string
  value?: string
  setValue: (name: string, value: string) => void
  error?: string
  clearError?: (name: string) => void
  onKeyDown?: (event: React.KeyboardEvent) => void
  className?: string
}

const TextField = ({
  name,
  label,
  value = "",
  setValue,
  error,
  clearError,
  onKeyDown,
  className,
}: TextFieldProps) => {
  const [isFocused, setFocused] = useState(false)
  const hasError = !!error

  const handleChange = (e: SyntheticEvent<HTMLInputElement>) => {
    if (error && clearError) {
      clearError(name)
    }
    setValue(name, e.currentTarget.value)
  }

  return (
    <div
      className={`textField ${isFocused ? "textField--focused" : ""} ${
        hasError && !isFocused ? "textField--error" : ""
      } ${className ? className : ""}`}
    >
      <label className={"textField__label"} htmlFor={name}>
        {label}
      </label>
      <input
        id={name}
        className="textField__input"
        name={name}
        type="text"
        value={value}
        onChange={handleChange}
        onFocus={() => setFocused(true)}
        onBlur={() => setFocused(false)}
        onKeyDown={onKeyDown}
      />
      {hasError ? <span className={"textField__error"}>{error}</span> : null}
    </div>
  )
}

export default TextField
