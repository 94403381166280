import React from "react"

import OfficeItem from "./OfficeItem"

import { OfficeType } from "../../types"

type OfficesProps = {
  title: string
  text: string
  offices: OfficeType[]
}

const Offices = ({ title, text, offices }: OfficesProps) => {
  const officeItems = offices?.map((office, i) => (
    <div key={`office-${i}`} className="col-md-6 col-xl-4">
      <OfficeItem office={office} />
    </div>
  ))

  return (
    <section className="c-offices">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xxl-10">
            <div className="c-clients__box">
              <div className="row mb-5">
                <div className="col-12 mb-2 mb-sm-3">
                  <h2 className="section-heading">{title}</h2>
                </div>
              </div>

              <div className="row pt-1">
                <div className="col-lg-10 col-xl-7 col-xxl-5">
                  <h3 className="section-subheading">{text}</h3>
                </div>
              </div>

              <div className="c-offices__blocks">
                <div className="row">{officeItems}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Offices
