import React from "react"
import { Helmet } from "react-helmet"

import { SeoMetaType } from "../../types"

const seoMeta = ({
  title,
  description,
  ogImage,
  canonicalLink,
  noIndex,
}: SeoMetaType) => {
  const meta = []
  const link = []
  const script = []

  meta.push({ name: `twitter:card`, content: `summary_large_image` })
  meta.push({
    name: "facebook-domain-verification",
    content: "jgbcb49ow1p2w2txjme5ktkhyosw0o",
  })
  script.push({
    src: `https://ipmeta.io/plugin.js`,
  })
  script.push({
    innerHTML: `
      window.dataLayer = window.dataLayer || [];
      function gtag() {
          dataLayer.push(arguments);
      }
      gtag("consent", "default", {
          ad_storage: "denied",
          analytics_storage: "denied",
          functionality_storage: "denied",
          personalization_storage: "denied",
          security_storage: "granted",
          wait_for_update: 2000,
      });
      gtag("set", "ads_data_redaction", true);`,
  })

  if (process.env.NODE_ENV === "production") {
    script.push({
      innerHTML: `
        var data  = provideGtmPlugin({
            gtmEventKey: 'ipmeta_loaded',
            apiKey: '40d515b1eacb1c8f0c6d305cd51cd50b1b0e1714a7c8907fc36e3c9a200b5b76',
        });
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push(data);
      `,
    })
  }
  if (title) {
    meta.push({ name: `og:title`, content: title })
    meta.push({ property: `og:title`, content: title })
    meta.push({ name: `twitter:title`, content: title })
  }

  if (description) {
    meta.push({ name: `description`, content: description })
    meta.push({ name: `og:description`, content: description })
    meta.push({ property: `og:description`, content: description })
    meta.push({ name: `twitter:description`, content: description })
  }

  if (canonicalLink) {
    meta.push({ name: `og:url`, content: canonicalLink })
    meta.push({ property: `og:url`, content: canonicalLink })
    link.push({ rel: "canonical", href: canonicalLink })
  }

  if (ogImage) {
    meta.push({ name: `og:image`, content: ogImage })
    meta.push({ property: `og:image`, content: ogImage })
    meta.push({ name: `twitter:image`, content: ogImage })
  }

  if (noIndex) {
    meta.push({ name: "robots", content: "noindex" })
  }

  return (
    <>
      <Helmet title={title} script={script} meta={meta} link={link} />
    </>
  )
}

export default seoMeta
