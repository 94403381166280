import React from "react"

import patternOrange from "../../assets/images/pattern-orange-small.svg"
import patternDark from "../../assets/images/hero-pattern-dark.svg"

import WhitepaperHeroForm, { WhitepaperFormProps } from "./WhitepaperHeroForm"

type BottomFormType = {
  form: { content: WhitepaperFormProps }
  docPath: string
  docName: string
  formSource: string
}

const BottomForm = ({ form, docPath, docName, formSource }: BottomFormType) => {
  return (
    <section className="c-bottomForm d-xl-none">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-8">
            <img
              className="c-bottomForm__patternOrange"
              src={patternOrange}
              alt="pattern simplity"
            />
            <img
              className="c-bottomForm__patternDark"
              src={patternDark}
              alt="pattern simplity"
            />
            <div className="c-bottomForm__form">
              <WhitepaperHeroForm
                {...form.content}
                docPath={docPath}
                docName={docName}
                formSource={formSource}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default BottomForm
