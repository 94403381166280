import React from "react"

import StoryblokLink from "../shared/StoryblokLink"

import heroPatternDark from "../../assets/images/hero-pattern-dark.svg"
import patternOrange from "../../assets/images/404-pattern-orange.svg"

import { ctaTarget } from "../../types"

type NothingFoundProps = {
  title: string
  text: string
  ctaButton: string
  ctaLink: ctaTarget
}

const NothingFound = ({
  title,
  text,
  ctaButton,
  ctaLink,
}: NothingFoundProps) => {
  return (
    <section className="c-nothingFound">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xxxl-4">
            <div className="c-nothingFound__content d-flex flex-column align-items-center">
              <h1 className="c-nothingFound__headline text-center">{title}</h1>
              <p className="c-nothingFound__text text-center">{text}</p>
              {ctaButton && (
                <StoryblokLink to={ctaLink} className="btn-secondary">
                  {ctaButton}
                </StoryblokLink>
              )}
            </div>
          </div>
        </div>
      </div>

      <img
        src={heroPatternDark}
        className="c-nothingFound__patternDark"
        alt=""
      />
      <img
        src={patternOrange}
        className="c-nothingFound__patternOrange"
        alt=""
      />
    </section>
  )
}

export default NothingFound
