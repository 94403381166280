import React from "react"

import StoryblokImage from "../shared/StoryblokImage"
import StoryblokLink from "../shared/StoryblokLink"

import LinkedInIcon from "../../assets/images/icons/linkedin.svg"
import MailIcon from "../../assets/images/icons/mail.svg"

import patternOrange from "../../assets/images/footer-pattern-orange.svg"
import patternDark from "../../assets/images/hero-pattern-dark.svg"

import { ctaTarget, StoryblokImageType } from "../../types"

type PersonBannerType = {
  title: string
  image: StoryblokImageType
  name: string
  position: string
  linkedin: ctaTarget
  mail: ctaTarget
  text: string
  cta: string
  openFormOverlay: () => void
}

const PersonBanner = ({
  title,
  image,
  name,
  position,
  linkedin,
  mail,
  text,
  cta,
  openFormOverlay,
}: PersonBannerType) => {
  return (
    <section className="c-personBanner">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xxl-10">
            <div className="row justify-content-center">
              <div className="col-xl-9 col-xxxl-8">
                <div className="c-personBanner__content">
                  <h2 className="c-personBanner__headline">{title}</h2>

                  <div className="d-flex">
                    <div className="c-personBanner__imageWrapper d-none d-lg-block">
                      <StoryblokImage
                        image={image}
                        className="c-personBanner__image"
                      />
                    </div>

                    <div className="c-personBanner__description">
                      <div className="c-personBanner__imageWrapper">
                        <StoryblokImage
                          image={image}
                          className="c-personBanner__image d-lg-none"
                        />
                      </div>

                      <div className="c-personBanner__descriptionBox">
                        <p className="c-personBanner__name">{name}</p>
                        <p className="c-personBanner__position">{position}</p>

                        <div className="c-personBanner__links d-flex align-items-center">
                          <StoryblokLink
                            to={linkedin}
                            className="c-personBanner__linkedin"
                          >
                            <LinkedInIcon />
                          </StoryblokLink>

                          <StoryblokLink
                            to={mail}
                            className="c-personBanner__mail"
                          >
                            <MailIcon />
                          </StoryblokLink>
                        </div>
                      </div>

                      <p className="c-personBanner__text">{text}</p>
                    </div>
                  </div>

                  <span
                    className="c-personBanner__cta btn-primary"
                    onClick={openFormOverlay}
                  >
                    {cta}
                  </span>

                  <img
                    src={patternOrange}
                    className="c-personBanner__patternOrange"
                    alt="contact simplity specialist"
                  />

                  <img
                    src={patternDark}
                    className="c-personBanner__patternDark"
                    alt="contact simplity specialist"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default PersonBanner
