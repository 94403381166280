import React from "react"
import { StoryblokImageType } from "../../types"

type StoryblokImageProps = {
  image?: StoryblokImageType
  className?: string
}

const StoryblokImage = ({ image, className }: StoryblokImageProps) => {
  if (!image || !image.filename) {
    return null
  }

  return (
    <img
      className={className}
      src={image.filename}
      alt={image.alt}
      title={image.title}
    />
  )
}

export default StoryblokImage
