import React, { useState, useRef } from "react"
import TeamTestimonialSlider from "./TeamTestimonialSlider"

import nextArrow from "../../assets/images/team-next-arrow.svg"
import prevArrow from "../../assets/images/team-prev-arrow.svg"

import { MemberType } from "../../types"

type TeamTestimonialProps = {
  members: MemberType[]
}

const TeamTestimonial = ({ members }: TeamTestimonialProps) => {
  const sliderRef = useRef(null)

  const showNext = () => {
    sliderRef.current.slickNext()
  }

  const showPrev = () => {
    sliderRef.current.slickPrev()
  }

  return (
    <div className="c-teamTestimonial">
      <TeamTestimonialSlider members={members} forwardedRef={sliderRef} />
      <div className="c-teamTestimonial__controls d-flex justify-content-end">
        <div
          className="c-teamTestimonial__controlButton"
          onClick={() => showPrev()}
        >
          <div className="c-teamTestimonial__hoverButton">
            <img src={prevArrow} alt="" />
          </div>
        </div>

        <div
          className="c-teamTestimonial__controlButton"
          onClick={() => showNext()}
        >
          <div className="c-teamTestimonial__hoverButton">
            <img src={nextArrow} alt="" />
          </div>
        </div>
      </div>
    </div>
  )
}

export default TeamTestimonial
