import React from "react"

import StoryblokLink from "../shared/StoryblokLink"

import { renderCardHeadline } from "../contentEdit"

import { WebinarContent } from "../../types"

type WebinarItemType = {
  full_slug: string
  content: WebinarContent
}

const WebinarItem = ({ full_slug, content }: WebinarItemType) => {
  const linkObject = {
    cached_url: full_slug,
    linktype: "story",
  }

  const cardHeadline = renderCardHeadline(
    content?.webinarTitle,
    "c-learnItem__heading"
  )

  return (
    <StoryblokLink to={linkObject} className="c-learnItem d-flex flex-column">
      <div className="c-learnItem__hoverWrapper">
        <div
          className="c-learnItem__imageWrapper"
          style={{ backgroundImage: `url(${content?.bigCardImage.filename})` }}
        />

        <div className="c-learnItem__content">
          <span className="c-learnItem__type">{content?.component}</span>

          {cardHeadline}

          <span className="btn-tertiary hover-bcgUnderline">
            Register for the webinar
          </span>
        </div>
      </div>
    </StoryblokLink>
  )
}

export default WebinarItem
