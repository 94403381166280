import React from "react"
import classNames from "classnames"
import { render } from "storyblok-rich-text-react-renderer"
import heroPatternDark from "../../assets/images/hero-pattern-dark.svg"
import heroPatternOrange from "../../assets/images/hero-pattern-orange.svg"
import { renderHeadline } from "../contentEdit"
import { BlogAuthorType, StoryblokImageType } from "../../types"
import StoryblokImage from "../shared/StoryblokImage"

type BlogPostHeroProps = {
  tag: string
  title: string
  authors: BlogAuthorType[]
  readTime: string
  headerImage: StoryblokImageType
  datePublished: string
}

const BlogPostHero = ({
  authors,
  headerImage,
  readTime,
  tag,
  title,
  datePublished,
}: BlogPostHeroProps) => {
  return (
    <section className={classNames("c-blogPostHero")}>
      <div className="container">
        <div className="row">
          <div className="col-12 c-blogPostHero__content">
            <div className="c-blogPostHero__tag">{tag}</div>
            {renderHeadline(title, "c-blogPostHero__headline")}
            <div className="c-blogPostHero__author">
              <div className="c-blogPostHero__authorPhotosWrapper">
                {authors.map(author => (
                  <StoryblokImage
                    image={author.profilePhoto}
                    className={"c-blogPostHero__authorPhoto"}
                    key={author.name}
                  />
                ))}
              </div>
              <div className="c-blogPostHero__nameAndDateWrapper">
                <div className="c-blogPostHero__authorName">
                  {authors.map(q => q.name).join(", ")}
                </div>
                <div className="c-blogPostHero__datePublished">
                  {datePublished} | {readTime}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="c-blogPostHero__imageWrapper">
        <div className="c-blogPostHero__bottomWhiteBackground" />
        <div className="c-blogPostHero__imageContainer">
          <div className="c-blogPostHero__imageBackground" />
          <img src={heroPatternDark} className="c-blogPostHero__patternDark" />
          <img
            src={heroPatternOrange}
            className="c-blogPostHero__patternOrange"
          />
          <StoryblokImage
            image={headerImage}
            className={"c-blogPostHero__image"}
          />
        </div>
      </div>
    </section>
  )
}
export default BlogPostHero
