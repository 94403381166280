import React from 'react'
import Layout from '../components/layout/Layout'
import { BlogType, SeoMetaType, } from '../types'
import BlogPostHero from '../components/blog/BlogPostHero'
import BlogPostText from '../components/blog/BlogPostText'
import BlogPostAuthorBio from '../components/blog/BlogPostAuthorBio'
import ThreeBlock from '../components/shared/ThreeBlock'
import CtaBanner from '../components/shared/CtaBanner'

type SolutionDetailProps = {
  seoMeta: SeoMetaType
  pathname: string
} & BlogType

const BlogPost = ({
                    seoMeta,
                    pathname,
                    blogAuthor,
                    blogBody,
                    blogTitle,
                    ctaBanner,
                    datePublished,
                    headerImage,
                    headerImageThumb,
                    leadParagraph,
                    readTime,
                    resources,
                    tag,
                  }: SolutionDetailProps) => {
  return (
    <Layout contentClass="BlogPostPage" seoMeta={seoMeta} pathname={pathname}>
      <BlogPostHero
        title={blogTitle}
        tag={tag}
        authors={(blogAuthor || []).map(q => q.content).filter(q => !!q)}
        headerImage={headerImage}
        readTime={readTime}
        datePublished={datePublished}
      />
      <BlogPostText leadParagraph={leadParagraph} blogBody={blogBody}/>
      <BlogPostAuthorBio
        authors={(blogAuthor || []).map(q => q.content).filter(q => !!q)}
      />
      <ThreeBlock title={'Learn more'} cards={resources}/>
      {ctaBanner && <CtaBanner ctaBanner={ctaBanner}/>}
    </Layout>
  )
}

export default BlogPost
