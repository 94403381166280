import React, { useRef, useEffect } from "react"
import Slider from "react-slick"

import StoryblokImage from "../shared/StoryblokImage"

import { TestimonialItem } from "../../types"

type TestimonialSliderProps = {
  activeSlide: number
  items: TestimonialItem[]
}

const TestimonialSlider = ({ activeSlide, items }: TestimonialSliderProps) => {
  const slider = useRef(null)

  useEffect(() => {
    slider.current.slickGoTo(activeSlide)
  }, [activeSlide])

  const settings = {
    infinite: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    dots: false,
    draggable: false,
  }

  const slides = items?.filter(slide => !!slide.content).map((slide, i) => (
    <div key={`testimonials-${i}`} className="c-testimonialSlider__item">
      <div className="c-testimonialSlider__item-text">
        {slide?.content.testimonialText}
      </div>

      <div className="d-flex flex-column flex-lg-row">
        <div className="c-testimonialSlider__imageWrapper">
          <StoryblokImage
            image={slide?.content.profilePhoto}
            className="c-testimonialSlider__image"
          />
        </div>

        <div className="c-testimonialSlider__author">
          <p className="c-testimonialSlider__author-text">
            <strong>
              {slide?.content.name}
              <span className="d-none d-lg-inline-block">,</span>
            </strong>
            &nbsp;
            <span className="c-testimonialSlider__author-position">
              {slide?.content.position}
            </span>
          </p>
          <StoryblokImage image={slide?.content.companyLogo} />
        </div>
      </div>
    </div>
  ))

  return (
    <div className="c-testimonialSlider">
      <Slider ref={slider} {...settings}>
        {slides}
      </Slider>
    </div>
  )
}

export default TestimonialSlider
