import React from "react"

import StoryblokLink from "../shared/StoryblokLink"

import { ctaTarget } from "../../types"

type NavDropdownMainLinkProps = {
  label: string
  link: ctaTarget
  pathname: string
}

const NavDropdownMainLink = ({
  label,
  link,
  pathname,
}: NavDropdownMainLinkProps) => {
  return (
    <li className="header__dropdown-item">
      <StoryblokLink
        to={link}
        className="hover-bcgUnderline"
        pathname={pathname}
      >
        {label}
      </StoryblokLink>
    </li>
  )
}

export default NavDropdownMainLink
