import React from "react"

import StoryblokLink from "./StoryblokLink"

import { renderCardHeadline } from "../contentEdit"

import { WebinarContent } from "../../types"

type ThreeBlockWebinarType = {
  full_slug: string
  content: WebinarContent
  ctaLabel?: string
}

const ThreeBlockWebinar = ({
  full_slug,
  content,
  ctaLabel = "Register for the webinar",
}: ThreeBlockWebinarType) => {
  const linkObject = {
    cached_url: full_slug,
    linktype: "story",
  }

  const cardHeadline = renderCardHeadline(
    content?.webinarTitle,
    "c-threeBlock__heading"
  )

  return (
    <StoryblokLink
      to={linkObject}
      className="c-threeBlock__item d-flex flex-column"
    >
      <div className="c-threeBlock__hoverWrapper">
        <div
          className="c-threeBlock__imageWrapper"
          style={{
            backgroundImage: `url(${content?.bigCardImage?.filename})`,
          }}
        />

        <div className="c-threeBlock__content">
          <span className="c-threeBlock__type">{content?.component}</span>

          {cardHeadline}

          <span className="btn-tertiary hover-bcgUnderline">{ctaLabel}</span>
        </div>
      </div>
    </StoryblokLink>
  )
}

export default ThreeBlockWebinar
