import React, { useState } from "react"

import ProjectEstimateForm from "./ProjectEstimateForm"
import ProjectEstimateSlider from "./ProjectEstimateSlider"

import PrevArrow from "../../assets/images/icons/prev-arrow-dark.svg"
import NextArrow from "../../assets/images/icons/next-arrow.svg"

import { ProjectEstimateFormProps } from "./ProjectEstimateForm"
import { TestimonialItem } from "../../types"
import patternOrange from "../../assets/images/pattern-orange-small.svg"
import patternDark from "../../assets/images/hero-pattern-dark3.svg"

type ProjectEstimateProps = {
  title: string
  form: { content: ProjectEstimateFormProps }
  slides: TestimonialItem[]
}

const ProjectEstimate = ({ title, form, slides }: ProjectEstimateProps) => {
  const [activeSlide, setSlide] = useState(0)
  const itemCount = slides?.length

  const showNext = () => {
    setSlide(activeSlide === itemCount - 1 ? 0 : activeSlide + 1)
  }

  const showPrev = () => {
    setSlide(activeSlide === 0 ? itemCount - 1 : activeSlide - 1)
  }

  return (
    <section className="c-projectEstimate">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xxl-10">
            <div className="row">
              <div className="col-xl-6">
                <div className="c-projectEstimate__content">
                  <h2 className="c-projectEstimate__title section-subheading">
                    {title}
                  </h2>

                  <ProjectEstimateSlider
                    activeSlide={activeSlide}
                    slides={slides}
                  />

                  <div className="c-projectEstimate__arrows d-flex align-items-center">
                    <PrevArrow onClick={() => showPrev()} />
                    <span className="c-testimonials__listing text-center">
                      {activeSlide + 1}/{itemCount}
                    </span>
                    <NextArrow onClick={() => showNext()} />
                  </div>
                </div>
              </div>

              <div className="col-xl-6">
                <div className="c-projectEstimate__formWrapper">
                  <div className="c-projectEstimate__form">
                    <ProjectEstimateForm
                      formSource={title}
                      {...form?.content}
                    />
                  </div>

                  <img
                    src={patternOrange}
                    alt="simplity project estimate"
                    className="c-projectEstimate__patternOrange"
                  />
                  <img
                    src={patternDark}
                    alt="simplity project estimate"
                    className="c-projectEstimate__patternDark"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default ProjectEstimate
