import React from "react"

import FiftyFiftyBlock from "../shared/FiftyFiftyBlock"

import { FiftyFiftyCardType } from "../../types"

type AccurityProps = {
  content: FiftyFiftyCardType[]
}

const Accurity = ({ content }: AccurityProps) => {
  return (
    <section className="c-accurity">
      <FiftyFiftyBlock content={content[0]} rightAlign={false} isLast={false} />
    </section>
  )
}

export default Accurity
