import React from "react"

import FiftyFiftyBlock from "../shared/FiftyFiftyBlock"

import { FiftyFiftyCardType } from "../../types"

type LegalProps = {
  content: FiftyFiftyCardType[]
}

const Legal = ({ content }: LegalProps) => {
  return (
    <section className="c-legal">
      <FiftyFiftyBlock content={content[0]} rightAlign={false} isLast={false} />
    </section>
  )
}

export default Legal
