import React from "react"

import StoryblokLink from "../shared/StoryblokLink"

import { renderCardHeadline } from "../contentEdit"

import { BlogContent } from "../../types"

type CaseStudyItemProps = {
  full_slug: string
  content: BlogContent
}

const BlogItem = ({ full_slug, content }: CaseStudyItemProps) => {
  const linkObject = {
    cached_url: full_slug,
    linktype: "story",
  }

  const cardHeadline = renderCardHeadline(
    content?.blogTitle,
    "c-learnItem__heading"
  )

  return (
    <StoryblokLink to={linkObject} className="c-learnItem d-flex flex-column">
      <div className="c-learnItem__hoverWrapper">
        <div
          className="c-learnItem__imageWrapper"
          style={{
            backgroundImage: `url(${content?.headerImageBigThumb?.filename})`,
          }}
        />

        <div className="c-learnItem__content">
          <span className="c-learnItem__type">{content?.component}</span>

          {cardHeadline}

          <span className="btn-tertiary hover-bcgUnderline">Read more</span>
        </div>
      </div>
    </StoryblokLink>
  )
}

export default BlogItem
