import React from 'react'
import orangePattern from '../../assets/images/footer-pattern-orange.svg'
import darkPattern from '../../assets/images/hero-pattern-dark.svg'
import { CtaNewsletterBannerContent } from '../../types'
import { useNewsletterSignup } from "../../newsletterSignup/newsletterSignup"

const CtaNewsletterBanner = ({ bannerTitle, bannerText, inputFieldText, bannerCtaButton }: CtaNewsletterBannerContent) => {
  const {
    email,
    setEmail,
    doSubmit,
    isFetching,
  } = useNewsletterSignup('Banner Newsletter Signup')

  return (
    bannerTitle && (
      <section className="c-ctaBanner c-ctaBanner--newsletter" id="newsletter">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-10 col-xl-8 col-xxl-6">
              <div className="c-ctaBanner__body d-flex flex-column align-items-center">
                <h4 className="c-ctaBanner__heading text-center">
                  {bannerTitle}
                </h4>

                <p className="c-ctaBanner__text text-center pb-5 mb-3">
                  {bannerText}
                </p>

                <div className="c-ctaBanner__inputFieldWrapper">
                  <input
                    type="text"
                    className="c-ctaBanner__inputField"
                    placeholder={inputFieldText}
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  <button
                    onClick={doSubmit}
                    disabled={isFetching}
                    className="btn-primary"
                  >
                    {bannerCtaButton}
                  </button>
                </div>

                <img src={orangePattern} className="c-ctaBanner__pattern c-ctaBanner__orangePattern" alt=""/>
                <img src={darkPattern} className="c-ctaBanner__pattern c-ctaBanner__darkPattern" alt=""/>
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  )
};

export default CtaNewsletterBanner
