import React, { useState } from "react"
import classNames from "classnames"

import TestimonialSlider from "./TestimonialSlider"

import PrevArrow from "../../assets/images/icons/prev-arrow.svg"
import NextArrow from "../../assets/images/icons/next-arrow.svg"

import patternDark from "../../assets/images/hero-pattern-dark2.svg"
import patternOrange from "../../assets/images/testimonial-pattern-orange.svg"

import { Testimonial } from "../../types"

type TestimonialsProps = {
  testimonials: Testimonial[]
  whiteBcg?: boolean
}

const Testimonials = ({
  testimonials,
  whiteBcg = false,
}: TestimonialsProps) => {
  const [activeSlide, setSlide] = useState(0)
  const itemCount = testimonials[0]?.testimonials.length

  const showNext = () => {
    setSlide(activeSlide === itemCount - 1 ? 0 : activeSlide + 1)
  }

  const showPrev = () => {
    setSlide(activeSlide === 0 ? itemCount - 1 : activeSlide - 1)
  }

  const renderClassNames = () =>
    classNames("c-testimonials", {
      "c-testimonials--whiteBcg": whiteBcg,
      "c-testimonials--oneItem": itemCount < 2,
    })

  return (
    <section className={renderClassNames()}>
      <div className="container">
        <div className="row justify-content-start">
          <div className="col-xxl-1" />

          <div className="col-sm-9 col-md-7 col-lg-6 col-xl-4 col-xxl-2">
            <div className="c-testimonials__menu">
              <h2 className="section-heading mb-5">
                {testimonials[0]?.headline}
              </h2>

              <h3 className="section-subheading pt-4">
                {testimonials[0]?.text}
              </h3>

              {itemCount >= 2 && (
                <div className="c-testimonials__arrows d-none d-xl-flex align-items-center">
                  <PrevArrow onClick={() => showPrev()} />
                  <span className="c-testimonials__listing text-center">
                    {activeSlide + 1}/{itemCount}
                  </span>
                  <NextArrow onClick={() => showNext()} />
                </div>
              )}
            </div>
          </div>

          <div className="col-xl-8 col-xxl-9">
            <div className="c-testimonials__content d-flex flex-column justify-content-end">
              <img
                src={patternOrange}
                className="c-testimonials__content-pattern"
                alt=""
              />
              <TestimonialSlider
                activeSlide={activeSlide}
                items={testimonials[0]?.testimonials}
              />
            </div>
            {itemCount >= 2 && (
              <div className="c-testimonials__arrows d-flex d-xl-none align-items-center">
                <PrevArrow onClick={() => showPrev()} />
                <span className="c-testimonials__listing text-center">
                  {activeSlide + 1}/{itemCount}
                </span>
                <NextArrow onClick={() => showNext()} />
              </div>
            )}
          </div>
        </div>
      </div>

      <img src={patternDark} className="c-testimonials__patternDark" alt="" />
    </section>
  )
}

export default Testimonials
