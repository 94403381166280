import React from "react"

import StoryblokLink from "../shared/StoryblokLink"

import { FooterLink } from "../../types"

type FooterBottomProps = {
  copyright?: string
  bottomLinks: FooterLink[]
}

const FooterBottom = ({ copyright, bottomLinks }: FooterBottomProps) => {
  const links = bottomLinks?.map((link, i) => (
    <li className="footer__bottom-listItem" key={`footer-bottom-link-${i}`}>
      <StoryblokLink to={link.link}>
        <span className="hover-bcgUnderline">{link.label}</span>
      </StoryblokLink>
    </li>
  ))

  return (
    <div className="row">
      <div className="col-12">
        <div className="footer__bottom d-flex flex-column flex-xl-row justify-content-between align-items-xl-center">
          <span className="order-2 order-xl-1">{copyright}</span>

          <ul className="footer__bottom-list d-flex flex-wrap order-1 order-xl-2">
            {links}
          </ul>
        </div>
      </div>
    </div>
  )
}

export default FooterBottom
