import React from "react"

import UseCaseCard from "./UseCaseCard"

import heroPatternDark from "../../assets/images/hero-pattern-dark.svg"

import { Card } from "../../types"

type UseCasesProps = {
  title: string
  text: string
  cards: Card[]
}

const UseCases = ({ title, text, cards }: UseCasesProps) => {
  const useCaseItems = cards?.map((card, i) => (
    <div key={`use-case-${i}`} className="col-lg-6 col-xl-4 mb-sm-5 mb-xl-0">
      <UseCaseCard card={card} />
    </div>
  ))

  return (
    <section className="c-useCases">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xxl-10">
            <div className="c-useCases__box">
              <div className="row mb-5">
                <div className="col-12 mb-2 mb-sm-3">
                  <h2 className="section-heading">{title}</h2>
                </div>
              </div>

              <div className="row pt-1">
                <div className="col-lg-10 col-xl-7 col-xxl-5">
                  <h3 className="section-subheading">{text}</h3>
                </div>
              </div>

              <div className="c-useCases__content">
                <div className="row justify-content-start">{useCaseItems}</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <img src={heroPatternDark} className="c-useCases__pattern" alt="" />
    </section>
  )
}

export default UseCases
