import React from "react"
import { render } from "storyblok-rich-text-react-renderer"

type TextBlockType = {
  sectionName: string
  title?: string
  content: any
  blockId?: string
  show?: boolean
}

const TextBlock = ({
  sectionName,
  title,
  content,
  blockId,
  show = true,
}: TextBlockType) => {
  const text = render(content)

  return show ? (
    <section className="c-textBlock" id={blockId}>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xxl-10">
            <div className="row justify-content-center">
              <div className="col-12 col-lg-8 col-xxl-6">
                <h3 className="section-heading c-textBlock__section mb-4 mb-sm-5">
                  {sectionName}
                </h3>

                {title && (
                  <h2 className="section-subheading c-textBlock__subheading pt-4 mt-4 mt-sm-1 pb-4 pb-sm-5">
                    {title}
                  </h2>
                )}

                <div className="c-textBlock__content">{text}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  ) : null
}

export default TextBlock
