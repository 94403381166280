import React from "react"

import Layout from "../components/layout/Layout"
import Hero from "../components/shared/Hero"
import Recognitions from "../components/aboutUs/Recognitions"
import Team from "../components/aboutUs/Team"
import Accurity from "../components/aboutUs/Accurity"
import Iconity from "../components/aboutUs/Iconity"
import Offices from "../components/aboutUs/Offices"
import Legal from "../components/aboutUs/Legal"

import {
  SeoMetaType,
  HeaderType,
  ClientsLogos,
  MemberType,
  FiftyFiftyCardType,
  OfficeType,
} from "../types"

type AboutUsPageProps = {
  seoMeta: SeoMetaType
  pathname: string
  header: HeaderType[]
  sectionName: string
  recognitionsTitle: string
  recognitions: ClientsLogos[]
  sectionName2: string
  meetTheTeamTitle: string
  cards: MemberType[]
  accurity: FiftyFiftyCardType[]
  iconity: FiftyFiftyCardType[]
  legalInfo: FiftyFiftyCardType[]
  sectionName3: string
  officeTitle: string
  offices: OfficeType[]
}

const AboutUs = ({
  seoMeta,
  pathname,
  header,
  sectionName,
  recognitionsTitle,
  recognitions,
  sectionName2,
  meetTheTeamTitle,
  cards,
  accurity,
  iconity,
  legalInfo,
  sectionName3,
  officeTitle,
  offices,
}: AboutUsPageProps) => {
  return (
    <Layout contentClass="AboutUsPage" seoMeta={seoMeta} pathname={pathname}>
      <Hero header={header} />
      <Recognitions
        title={sectionName}
        text={recognitionsTitle}
        logos={recognitions}
      />
      <Team title={sectionName2} text={meetTheTeamTitle} members={cards} />
      <Accurity content={accurity} />
      <Iconity content={iconity} />
      <Offices title={sectionName3} text={officeTitle} offices={offices} />
      <Legal content={legalInfo} />
    </Layout>
  )
}

export default AboutUs
