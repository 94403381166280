import React from "react"
import { useZohoFormLogic, ZohoFormFields } from "../zohoForms/zohoLogic"
import { CommonFormProps } from "../../types"
import TextField from "../zohoForms/TextField"
import CheckBox from "../zohoForms/CheckBox"
import TextArea from "../zohoForms/TextArea"
import fetchingGif from "../../assets/images/fetching.gif"

export const ZOHO_ESTIMATE_SUBMIT_URL =
  "https://form.simplity.ai/simplitygroup/form/SimplityProjectEstimateForm/formperma/YNptk4-c13cS4nZ1ywWdQ1ZeDTl0Y7Efh0Ee9EkkJM0/htmlRecords/submit"

export type EstimateFormFields = {
  firstName: string
  lastName: string
  email: string
  message: string
  marketing: boolean
}

const zohoProjectEstimateFormFields: ZohoFormFields<EstimateFormFields> = {
  firstName: {
    zohoFieldName: "SingleLine7",
    initialValue: "",
    required: true,
  },
  lastName: {
    zohoFieldName: "SingleLine8",
    initialValue: "",
    required: true,
  },
  email: {
    zohoFieldName: "Email",
    initialValue: "",
    required: true,
    runEmailValidation: true,
  },
  message: {
    zohoFieldName: "MultiLine",
    initialValue: "",
    required: true,
  },
  marketing: {
    zohoFieldName: "DecisionBox",
    initialValue: false,
    required: false,
  },
}

export type ProjectEstimateFormProps = {
  formSource: string
  messageLabel: string
} & CommonFormProps

const ProjectEstimateForm = ({
  titleForm,
  firstNameLabel,
  lastNameLabel,
  messageLabel,
  emailLabel,
  marketingLabel,
  submitLabel,
  formSource,
  successRedirectUrl,
  requiredFieldErrorMessage,
  validEmailErrorMessage,
  submitErrorMessage,
}: ProjectEstimateFormProps) => {
  const {
    values,
    errors,
    isFetching,
    submitError,
    setValue,
    clearError,
    doSubmit,
  } = useZohoFormLogic<EstimateFormFields>({
    formIdentifier: formSource,
    zohoSubmitUrl: ZOHO_ESTIMATE_SUBMIT_URL,
    successRedirectUrl: successRedirectUrl,
    requiredFieldValidationMessage: requiredFieldErrorMessage,
    validEmailValidationMessage: validEmailErrorMessage,
    submitErrorMessage: submitErrorMessage,
    zohoFormFields: zohoProjectEstimateFormFields,
  })

  return (
    <div className="form__container">
      {titleForm && <h3 className="form__headline">{titleForm}</h3>}

      <div className="form__row">
        <TextField
          name="firstName"
          label={firstNameLabel}
          value={values.firstName}
          setValue={setValue}
          error={errors.firstName}
          clearError={clearError}
        />
        <TextField
          name="lastName"
          label={lastNameLabel}
          value={values.lastName}
          setValue={setValue}
          error={errors.lastName}
          clearError={clearError}
        />
      </div>
      <TextField
        name="email"
        label={emailLabel}
        value={values.email}
        setValue={setValue}
        error={errors.email}
        clearError={clearError}
      />
      <TextArea
        name="message"
        label={messageLabel}
        value={values.message}
        setValue={setValue}
        error={errors.message}
        clearError={clearError}
        className="mt-n3"
      />
      <div className="form__checkBoxContainer">
        <CheckBox
          name="marketing"
          label={marketingLabel}
          value={values.marketing}
          setValue={setValue}
        />
      </div>
      <div className="form__buttonContainer">
        {isFetching ? (
          <div className="form__fetchingBox">
            <img src={fetchingGif} />
          </div>
        ) : (
          <button onClick={() => doSubmit()} className="form__submit">
            {submitLabel}
          </button>
        )}
        {!!submitError && <p className="form__submit-error">{submitError}</p>}
      </div>
    </div>
  )
}

export default ProjectEstimateForm
