import React from "react"
import { SubmenuItem } from "../../types"

import StoryblokLink from "../shared/StoryblokLink"

type NavDropdownSmallItemsProps = {
  links: SubmenuItem[]
  pathname: string
}

const NavDropdownSmallItems = ({
  links,
  pathname,
}: NavDropdownSmallItemsProps) => {
  const subItems = links?.map((item, i) => (
    <li className="header__subdropdown-item" key={`subItem-${i}`}>
      <StoryblokLink
        to={item.link}
        className="hover-bcgUnderline"
        pathname={pathname}
      >
        {item.label}
      </StoryblokLink>
    </li>
  ))

  return (
    <li className="header__dropdown-item">
      <ul className="header__subdropdown">{subItems}</ul>
    </li>
  )
}

export default NavDropdownSmallItems
