import React from "react"

import ServiceItem from "./ServiceCard"
import ServiceAccordion from "./ServiceAccordion"

import pattern from "../../assets/images/pattern-dark-big.svg"

import { cutInLines } from "../contentEdit"

import { ServiceCardType } from "../../types"
import { useExpandableCard } from "./useExpandableCardHook"

type DataCurationProps = {
  title: string
  text: string
  cards: ServiceCardType[]
}

const DataScience = ({ title, text, cards }: DataCurationProps) => {
  const {
    cardsContainer,
    closeCard,
    expandedDirection,
    isCardExpanded,
    openCard,
  } = useExpandableCard()

  const cardItems = cards?.map((card, i) => (
    <div key={`service-item-${i}`} className="col-sm-6 col-xl-4 col-xxl-3">
      <ServiceItem
        card={card}
        isExpanded={isCardExpanded(i)}
        onClick={elm => openCard(i, elm)}
        onClose={closeCard}
        expandDirection={expandedDirection}
      />
    </div>
  ))

  const mobileItems = cards?.map((card, i) => {
    return (
      <div className="col-12" key={`service-accordion-${i}`}>
        <ServiceAccordion card={card} i={i} identifierType="science" />
      </div>
    )
  })

  return (
    <section className="c-dataScience" id="analytics-and-data-science">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xxl-10">
            <div className="row">
              <div className="col-lg-10 col-xl-8 col-xxl-6">
                <div className="c-dataCuration__box">
                  <div className="c-dataCuration__headline">
                    {cutInLines(title)}
                  </div>

                  <p className="c-dataCuration__text">{text}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="c-dataScience__belt">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xxl-10">
              <div className="c-dataCuration__beltBox">
                <div className="row d-none d-sm-flex" ref={cardsContainer}>
                  {cardItems}
                </div>

                <div className="row d-sm-none">{mobileItems}</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <img src={pattern} className="c-dataScience__patternTop" alt="" />
      <img src={pattern} className="c-dataScience__patternBottom" alt="" />
    </section>
  )
}

export default DataScience
